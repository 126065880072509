import React, { createContext, useEffect, useState, ReactNode } from "react";
import { showAlert } from "../utils/helpers";

export const Context = createContext(null);

export const ContextProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  const [serviceList, setServiceList] = useState([]);
  const [vserviceList, setVServiceList] = useState([]);
  const [vtpBalance, setVtpBalance] = useState("");
  const [ufpBalance, setUfpBalance] = useState("");

  const [validUser, setValidUser] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [transactions, setTransactions] = useState([]);

  //const apiURL = "http://localhost/swapeki/api.php";
  //const domain = "http://localhost/swapeki/";
  const apiURL = "https://app.swapeki.com/api.php";
  const domain = "https://app.swapeki.com/";
  const ufitpayKey = "TSVLWCB5TO5RfJFCNkwSX8z0F8ZwD91";
  const ufitpayToken = "TS66uEwtekJEcWiwzC1gDdiDzKcJFk1";
  const ufitpayBase = "https://api.ufitpay.com/v1/";
  const vtpassBase = "https://sandbox.vtpass.com/api/";
  //const vtpassBase = "https://api-service.vtpass.com/api/";
  const vtpassApiKey = "5613261b1a936b7db8f3bc513a78b4e4";
  const vtpassPubKey = "PK_304ea1777767743c1d1c6924858113668eb763d31ef";
  const vtpassSecKey = "SK_329cfd9835386ececcb69a440d38152b3fbc762d218";

  const fetchUServiceList = async () => {
    try {
      const response = await fetch(ufitpayBase + "services", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": ufitpayKey,
          "API-Token": ufitpayToken,
        },
      });

      const result = await response.json();
      if (result.status === "success") {
        setServiceList(result.data);
        console.log("Service list fetched successfully:", result.data);
      } else {
        console.log("Failed to fetch service list:", result);
      }
    } catch (error) {
      console.error("Error fetching service list:", error);
    }
  };

  useEffect(() => {
    fetchUServiceList();
  }, []);

  const getUfpBalance = async () => {
    try {
      const response = await fetch(ufitpayBase + "balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": ufitpayKey,
          "API-Token": ufitpayToken,
        },
      });

      const result = await response.json();
      if (result.status === "success") {
        setUfpBalance(result.data.balance);
        //  console.log("UFP Balance:", result.data);
      } else {
        console.log("Failed to fetch:", result);
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };

  useEffect(() => {
    getUfpBalance();
  }, []);

  const fetchVServiceList = async () => {
    try {
      const response = await fetch(vtpassBase + "service-categories", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "public-key": vtpassPubKey,
        },
      });

      const result = await response.json();
      if (result.response_description === "000") {
        setVServiceList(result.content);
        console.log("VTpass Service:", result.content);
      } else {
        console.log("Failed to fetch service list:", result);
      }
    } catch (error) {
      console.error("Error fetching service list:", error);
    }
  };

  useEffect(() => {
    fetchVServiceList();
  }, []);

  const getVtpBalance = async () => {
    try {
      const response = await fetch(vtpassBase + "balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "public-key": vtpassPubKey,
        },
      });

      const result = await response.json();
      if (result.code == "1") {
        setVtpBalance(result.contents.balance);
        console.log("VTpass balance:", result.contents.balance);
      } else {
        console.log("Failed to fetch vtpass balance:", result);
      }
    } catch (error) {
      console.error("Error fetching service list:", error);
    }
  };

  useEffect(() => {
    getVtpBalance();
  }, []);

  const getUfpVendors = async (service_id) => {
    try {
      const response = await fetch(
        ufitpayBase + `vendors?service_id=${service_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": ufitpayKey,
            "API-Token": ufitpayToken,
          },
        }
      );

      const result = await response.json();
      if (result.status === "success") {
        // setServiceList(result.data);
        console.log("UFP Vendors list fetched successfully:", result.data);
      } else {
        console.log("Failed to fetch service list:", result);
      }
    } catch (error) {
      console.error("Error fetching service list:", error);
    }
  };

  useEffect(() => {
    //  getUfpVendors("0036");
  }, []);

  const getSettings = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_settings" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setSettings(data[0]);
        //    console.log("Fetched settings:", data[0]);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  const getUserInfo = async () => {
    const token = localStorage.getItem("swapekis_email_address");
    if (!token || token === null || token === undefined) {
      setValidUser(false);
      setUserInfo(null);
      return false;
    }
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_user_info", email: token }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
        return false;
      } else {
        setUserInfo(data[0]); // Assuming data[0] is the userInfo object
        setValidUser(true);

        console.log("Fetched info:", data[0]);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    if (!localStorage) {
      return;
    }
    getUserInfo();
  }, [localStorage]);

  const getTransactions = async () => {
    if (userInfo === null) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_transactions",
          email: userInfo.email,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setTransactions(data);

        //   console.log("Fetched settings:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    getTransactions();
    // console.log("LS", lastSlide);
  }, [userInfo]);

  useEffect(() => {
    getSettings();
  }, []);

  const logOut = async () => {
    localStorage.removeItem("swapekis_email_address");
    localStorage.removeItem("swapekis_tx_pin");

    window.location.replace("login");
  };

  const copy = (text) => {
    if (text) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(
          () => {
            showAlert({
              title: "Success!",
              text: "Copied to clipboard",
              icon: "info",
              confirmButtonText: "Ok",
            });
          },
          (err) => {
            showAlert({
              title: "Error!",
              text: `Could not copy text: ${err}`,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        );
      } else {
        // Fallback method for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          showAlert({
            title: "Success!",
            text: "Copied to clipboard",
            icon: "info",
            confirmButtonText: "Ok",
          });
        } catch (err) {
          showAlert({
            title: "Error!",
            text: `Could not copy text: ${err}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        document.body.removeChild(textArea);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        validUser,
        userInfo,
        apiURL,
        settings,
        ufitpayKey,
        ufitpayToken,
        ufitpayBase,
        vtpassApiKey,
        vtpassBase,
        vtpassPubKey,
        vtpassSecKey,
        vtpBalance,
        ufpBalance,
        getUfpVendors,
        getUserInfo,
        getTransactions,
        transactions,
        copy,
        loading,
        setLoading,
        logOut,
        domain,
      }}
    >
      {children}
    </Context.Provider>
  );
};
