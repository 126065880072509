import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/contex";

const Header = ({ userInfo }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const avatarRef = useRef(null);
  const navigate = useNavigate();
  const context = useContext(Context);

  const { logOut, domain } = context;

  // Function to handle back navigation
  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // Toggle the menu state
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      avatarRef.current &&
      !avatarRef.current.contains(event.target) // Check if the click is not on the avatar
    ) {
      setMenuOpen(false); // Close the menu if clicking outside
    }
  };
  // Add event listener for detecting clicks outside the menu
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="header">
      {/* Back Arrow Button */}
      <div className="header-item back-button" onClick={handleBackClick}>
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </div>

      {/* Logo in the Middle */}

      <div className="header-item logo">
        <a href="https://swapeki.com/">
          {" "}
          <img src="assets/images/logo.png" alt="Logo" />
        </a>
      </div>

      <div className="header-item avatar">
        {/* Avatar */}
        <img
          ref={avatarRef}
          src={`${
            userInfo?.photo
              ? domain + userInfo.photo
              : "assets/images/avatar.svg"
          }`}
          alt="Profile"
          className="rounded-circle"
          width={40}
          onClick={toggleMenu} // Toggle the menu when clicked
          style={{ cursor: "pointer" }} // Show pointer to indicate clickable element
        />

        {/* Submenu */}
        {menuOpen && (
          <div ref={menuRef} className="tdropdown-menu">
            <ul>
              <li onClick={() => setMenuOpen(false)}>
                <a href="https://swapeki.com/" className="text-warning">
                  Home
                </a>
              </li>
              <li onClick={() => setMenuOpen(false)}>
                <a href="/settings" className="text-warning">
                  Settings
                </a>
              </li>

              <li onClick={logOut}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
