import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  copy,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card, ListGroup } from "react-bootstrap";

const Stats = () => {
  const [vcode, setVcode] = useState(null);
  const [stat, setStat] = useState({
    total_transactions: 0,
    total_spent: 0,
    referrals: [],
  });

  const context = useContext(Context);

  const { userInfo, transactions, validUser, domain, apiURL } = context;

  const getStat = async () => {
    if (!userInfo) return;

    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "get_user_summary",
          email: userInfo.email,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;

      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setStat(data);
        console.log("Fetched stat:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    getStat();
    // console.log("LS", lastSlide);
  }, [userInfo]);

  useEffect(() => {
    const checkcode = async () => {
      if (localStorage?.getItem("swapekis_email_code") == null) {
        return;
      } else {
        setVcode(localStorage?.getItem("swapekis_email_code"));
      }
    };
    checkcode();
  }, [localStorage]);

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row className="align-items-center text-center my-4">
              <Col className="text-white ml-3" xs={12}>
                <h5> </h5>
                <h2>{userInfo?.first_name.toUpperCase()}'s STATS</h2>
              </Col>
            </Row>

            {/* Balance Card */}
            <Row>
              {/* Total Transactions Card */}
              <Col md={3} className="mb-4">
                <Card
                  style={{
                    backgroundColor: "#6f42c1",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                  className="text-center p-3"
                >
                  <Card.Body>
                    <div className="icon mb-2" style={{ fontSize: "1.5rem" }}>
                      <i className="fa fa-exchange"></i>{" "}
                      {/* FontAwesome icon */}
                    </div>
                    <Card.Title className="h5">Total Transactions</Card.Title>
                    <Card.Text className="h3">
                      {stat["total_transactions"]}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              {/* Total Spent Card */}
              <Col md={3} className="mb-4">
                <Card
                  style={{
                    backgroundColor: "#0dcaf0",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                  className="text-center p-3"
                >
                  <Card.Body>
                    <div className="icon mb-2" style={{ fontSize: "1.5rem" }}>
                      <i className="fa fa-dollar"></i> {/* FontAwesome icon */}
                    </div>
                    <Card.Title className="h5">Total Spent</Card.Title>
                    <Card.Text className="h3">
                      {naira}
                      {formatNumber(stat["total_spent"])}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              {/* Total Referrals Card */}
              <Col md={3} className="mb-4">
                <Card
                  style={{
                    backgroundColor: "#198754",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                  className="text-center p-3"
                >
                  <Card.Body>
                    <div className="icon mb-2" style={{ fontSize: "1.5rem" }}>
                      <i className="fa fa-users"></i> {/* FontAwesome icon */}
                    </div>
                    <Card.Title className="h5">Total Referrals</Card.Title>
                    <Card.Text className="h3">
                      {stat["referrals"].length}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              {/* Total Ref Bonus Card */}
              <Col md={3} className="mb-4">
                <Card
                  style={{
                    backgroundColor: "#ffc107",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                  className="text-center p-3"
                >
                  <Card.Body>
                    <div className="icon mb-2" style={{ fontSize: "1.5rem" }}>
                      <i className="fa fa-gift"></i> {/* FontAwesome icon */}
                    </div>
                    <Card.Title className="h5">Total Ref Bonus</Card.Title>
                    <Card.Text className="h3">
                      {naira}
                      {formatNumber(
                        (+stat["referrals"].length * 1000).toString()
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Expense Card */}
            <Row className="my-4 text-center">
              <Col>
                <Ads />
              </Col>
            </Row>

            {/* Recent Transactions */}
            <Row className="align-items-center">
              <Col xs="9">
                <h3>MY REFERRALS</h3>
              </Col>
              <Col xs="3">
                <h3>TOTAL SPENT</h3>
              </Col>
            </Row>
            {stat["referrals"].length > 0 ? (
              <div className="recent-transactions mt-4">
                <ListGroup variant="flush" className="mt-3">
                  {stat["referrals"].map((tx, index) => (
                    <ListGroup.Item
                      key={`tx_${index}`}
                      className="d-flex justify-content-between"
                      style={{ borderBottom: "1px yellow dotted" }}
                    >
                      <div>
                        <h6>{tx.first_name}</h6>
                        <br />
                        <small>{tx.email}</small>
                      </div>
                      <div>
                        <h6>
                          {naira}
                          <b>{formatNumber(tx.total_spent)}</b>
                        </h6>
                        <small className={txColor(tx.status)}>
                          {tx.ref_paid === "yes" ? (
                            <b className="text-success">Paid</b>
                          ) : (
                            <b className="text-warning">Pending</b>
                          )}
                        </small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            ) : (
              <div className="text-center p-4">
                <img src="assets/images/fly.gif" height={80} />
                <p>No referrals found.</p>
              </div>
            )}
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Stats;
