import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
  Alert,
} from "react-bootstrap";

const Send = () => {
  // State management

  const [amount, setAmount] = useState("");
  const [recipient, setRecipient] = useState("");

  const context = useContext(Context);
  const [rInfo, setRInfo] = useState({});
  const [success, setSuccess] = useState(false);
  const [pin, setPin] = useState("");

  const [pinModal, setPinModal] = useState(false);

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
    getUserInfo,
    domain,
  } = context;

  const handleC2CPay = async (pin) => {
    if (userInfo.tx_pin === null) {
      presentToast(
        "error",
        "top-center",
        "Please set your transaction PIN.",
        () => {
          window.location.replace("set-pin");
        }
      );

      return;
    }
    if (!recipient) {
      presentToast("error", "top-center", "Type recipient email.", () => {});

      return;
    }
    if (!amount || parseFloat(amount) === 0) {
      presentToast("error", "top-center", "Invalid amount.", () => {});

      return;
    }

    if (+amount > +userInfo?.ngn_balance) {
      presentToast(
        "error",
        "top-center",
        "You do not have sufficient funds in your wallet.",
        () => {
          window.location.replace("deposit");
        }
      );

      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("error", "top-center", "Incorrect PIN.", () => {});

      return;
    }
    setLoading(true);
    setRInfo({});
    try {
      // Prepare data as URLSearchParams
      const params = new URLSearchParams();
      params.append("recipient", recipient);
      params.append("sender", userInfo.email);
      params.append("amount", amount);
      params.append("name", userInfo.first_name + " " + userInfo.last_name);
      params.append("action", "send_c_to_c");

      const response = await axios.post(apiURL, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      console.log("Response:", response); // Log the full response object

      if (response.data.error) {
        presentToast("error", "top-center", response.data.error, () => {});
      } else {
        await getUserInfo();
        presentToast("success", "top-center", response.data.message, () => {});
      }
    } catch (error) {
      console.log("Error:", error); // Log the error for debugging
      presentToast("error", "top-center", "Something went wrong...", () => {});
    }
    setLoading(false);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <div className="text-center">
              <h2 className="mb-4">Send Money</h2>
              <h4 className="mb-4">
                Current balance: {naira}
                {formatNumber(userInfo?.ngn_balance)}
              </h4>
            </div>

            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                {/* Change Password */}
                <Row className="mb-4">
                  <Col md={12}>
                    <Form>
                      <Form.Group
                        controlId="formNewPassword"
                        className="mb-3 form-group"
                      >
                        <Form.Label>Recipient's email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Type Recipient's email address"
                          value={recipient}
                          onChange={(e) => setRecipient(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="formConfirmPassword"
                        className="mb-3 form-group"
                      >
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="How much to send"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </Form.Group>
                      {recipient && amount && (
                        <div className="text-center">
                          <Button
                            variant="primary"
                            type="button"
                            onClick={() => setPinModal(true)}
                          >
                            Send {naira}
                            {formatNumber(amount)}
                          </Button>
                        </div>
                      )}
                    </Form>
                  </Col>
                </Row>

                {/* Change Transaction PIN */}
                <Row className="mb-4">
                  <Col xs="12" className="text-center my-5">
                    Need some help?
                    <Button
                      variant="link"
                      className="text-warning"
                      as={Link}
                      to="https://swapeki.com/contact-us/"
                    >
                      <b>Contact support</b>
                    </Button>
                  </Col>
                  <p>&nbsp;</p>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>

            <Modal
              show={pinModal}
              onHide={() => setPinModal(false)}
              backdrop="static"
              dialogClassName="modal-bg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Authorize Transaction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Enter your PIN to authorize this transaction.</p>
                <Form.Group>
                  <Form.Control
                    style={{ color: "#333333 !important" }}
                    type="password"
                    placeholder="Enter PIN"
                    maxLength="4"
                    inputMode="numeric"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setPinModal(false)}>
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    handleC2CPay(pin);
                    setPinModal(false);
                  }}
                >
                  Proceed
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Send;
