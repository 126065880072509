import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { FlutterPay } from "../../components/Pay/PaymentApi";

import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
} from "react-bootstrap";

const Deposit = () => {
  const [amount, setAmount] = useState("");
  const [grossAmount, setGrossAmount] = useState(0);

  const [customerDetails, setCustomerDetails] = useState({
    email: "",
    phonenumber: "",
    name: "",
  });
  const [selectedCurrency, setSelectedCurrency] = useState("NGN");
  const [ngnMethod, setNgnMethod] = useState("");
  const [vcode, setVcode] = useState(null);

  const context = useContext(Context);

  const { userInfo, transactions, validUser, settings } = context;

  useEffect(() => {
    const checkcode = async () => {
      if (localStorage?.getItem("swapekis_email_code") == null) {
        return;
      } else {
        setVcode(localStorage?.getItem("swapekis_email_code"));
      }
    };
    checkcode();
  }, [localStorage]);

  useEffect(() => {
    if (!amount || !settings) {
      setGrossAmount(0);
      return;
    }
    const gross = +amount + getPercentage(+amount, settings?.card_deposit_fee);
    setGrossAmount(gross);
  }, [amount, settings]);

  useEffect(() => {
    if (userInfo) {
      setCustomerDetails({
        email: userInfo.email || "",
        phonenumber: userInfo.phone || "",
        name: `${userInfo.first_name} ${userInfo.last_name}` || "",
      });
    }
  }, [userInfo]);

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row>
              <Col xs="2"></Col>
              <Col xs="8">
                <Row>
                  <Col className="text-center" size="12">
                    <div className="ion-margin">
                      <img
                        src="assets/images/naira.png"
                        style={{ width: "70px", height: "auto" }}
                      />
                    </div>
                    <h2 className="">
                      Fund Your {settings?.app_name} Naira Wallet
                    </h2>
                    <p>
                      Please enter the amount you wish to fund and we will
                      redirect you to our payment processor to complete the
                      payment.{" "}
                      <b>
                        A processing fee of {settings?.card_deposit_fee}% will
                        apply
                      </b>
                    </p>
                  </Col>
                  <Col xs="12" className="text-center">
                    <InputGroup className="mb-3" size="lg">
                      <InputGroup.Text id="basic-addon1">
                        {naira}
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        min={0}
                        placeholder="Amount"
                        aria-label="Amount"
                        aria-describedby="basic-addon1"
                        onInput={(e) => setAmount(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="12">
                    {amount && (
                      <FlutterPay
                        amount={+amount}
                        grossAmount={grossAmount}
                        customer={customerDetails}
                        //  publicKey="FLWPUBK_TEST-69dea5a2962da3e394b1ce8d8b894add-X"
                        //    txRef={`tx_${Date.now()}`}
                        title="Deposit Funds"
                        description={`Deposit funds into your ${settings?.app_name} wallet.`}
                        //   logo="assets/logo/vinpace.png"
                      />
                    )}
                  </Col>
                  <Col xs="12" className="text-center">
                    Need some help?
                    <Button
                      variant="link"
                      className="text-warning"
                      as={Link}
                      to="https://swapeki.com/contact-us/"
                    >
                      <b>Contact support</b>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs="2"></Col>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Deposit;
