import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
  copy,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";

const Electricity = () => {
  const [vendors, setVendors] = useState([]);

  const [vendor, setVendor] = useState({});
  const [meterDetails, setMeterDetails] = useState({});
  const [buyDetails, setBuyDetails] = useState({});

  const [packages, setPackages] = useState("prepaid");
  const [meterNumber, setMeterNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("airtime");
  const context = useContext(Context);
  const [pin, setPin] = useState("");

  const [pinModal, setPinModal] = useState(false);

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
  } = context;

  const fetchServiceID = async (identifier) => {
    setLoading(true);
    try {
      const response = await fetch(
        vtpassBase + `services?identifier=${identifier}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("VENDOR", result.content);
        setLoading(false);

        return result.content;
      } else {
        setLoading(false);

        return null;
      }
    } catch (error) {
      console.error("Error fetching service ID:", error);
      setLoading(false);

      return null;
    }
  };

  const verifyMeter = async () => {
    if (!meterDetails) {
      presentToast("warning", "top-center", `Meter number is empty.`, () => {});
      return;
    }
    if (Object.keys(vendor).length === 0) {
      presentToast("warning", "top-center", `Please choose a vendor`, () => {});

      return;
    }
    setMeterDetails({});
    setBuyDetails({});
    setLoading(true);
    const data = {
      serviceID: vendor.serviceID,
      type: packages,
      billersCode: String(meterNumber),
    };
    try {
      const response = await fetch(vtpassBase + `merchant-verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.code === "000" && !result.content.error) {
        console.log("verified", result);
        setMeterDetails(result.content);
        setLoading(false);
      } else {
        console.log("not verified", result);
        setLoading(false);
        presentToast(
          "error",
          "top-center",
          `${result.content.error}`,
          () => {}
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const as = await fetchServiceID("electricity-bill");
        //   const ds = await fetchServiceID("data");

        if (as) {
          setVendors(as);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  }, [vtpassBase, vtpassApiKey, vtpassPubKey]);

  const handlePay = async (pin) => {
    if (userInfo.tx_pin === null) {
      presentToast(
        "warning",
        "top-center",
        `Please set your transaction PIN first.`,
        () => {
          window.location.replace("set-pin");
        }
      );

      return;
    }

    // Validation checks...
    if (!amount) {
      presentToast(
        "warning",
        "top-center",
        `Please enter an amount.`,
        () => {}
      );

      return;
    }

    if (+amount > +vtpBalance) {
      presentToast(
        "warning",
        "top-center",
        `Service not available at the moment.`,
        () => {}
      );

      return;
    }
    if (+amount > +userInfo?.ngn_balance) {
      presentToast(
        "warning",
        "top-center",
        `You do not have sufficient funds in your wallet.`,
        () => {
          window.location.replace("deposit");
        }
      );
      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("warning", "top-center", `Incorrect PIN!`, () => {});
      return;
    }
    setBuyDetails({});
    const id = generateRequestId();
    setLoading(true);
    const data = {
      serviceID: vendor.serviceID,
      amount: +amount,
      request_id: id,
      billersCode: meterNumber,
      variation_code: packages,
      phone: userInfo.phone,
    };

    try {
      const response = await fetch(vtpassBase + "pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.code === "000" || result.code === "099") {
        console.log("Electricity purchase successful:", result);
        requeryTransaction(id);
      } else {
        presentToast(
          "error",
          "top-center",
          `Electricity purchase failed: ${result.response_description}`,
          () => {}
        );

        console.log("Electricity top-up failed:", result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error top-up:", error);
      setLoading(false);
    }
  };

  const requeryTransaction = async (requestId) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${vtpassBase}requery`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "secret-key": vtpassSecKey,
          },
          body: JSON.stringify({ request_id: requestId }),
        });

        const result = await response.json();
        if (
          result.code === "000" &&
          result.content.transactions.status === "delivered"
        ) {
          console.log("Transaction delivered:", result);
          clearInterval(interval);

          const response = await fetch(apiURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              action: "record_tx_and_debit",
              title: result.content.transactions.product_name,
              desc: result.content.transactions.product_name,
              amount: (
                +result.content.transactions.amount +
                +result.content.transactions.convinience_fee
              ).toString(),
              ref: result.content.transactions.transactionId,
              our_ref: requestId,
              status: "Complete",
              email: String(userInfo?.email),
              payload: JSON.stringify(result),
              type: "electricity",
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          setBuyDetails(result);
          setVendor({});
          setMeterDetails({});
          await context.getUserInfo();
          await context.getTransactions();
          setLoading(false);
          presentToast(
            "success",
            "top-center",
            `Your electricity purchase was successful.`,
            () => {}
          );
        } else {
          presentToast(
            "error",
            "top-center",
            `${result.content.transactions.status}`,
            () => {}
          );

          console.log(
            "Transaction status:",
            result.content.transactions.status
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error re-querying transaction:", error);
        clearInterval(interval);
        setLoading(false);
      }
    }, 2000); // Re-query every 2 seconds
  };

  const getToken = (str) => {
    const tokenArray = str.split(" ");
    if (tokenArray.length === 3) {
      return tokenArray[2];
    } else if (tokenArray.length === 2) {
      return tokenArray[1];
    }
    if (tokenArray.length === 1) {
      return tokenArray[0];
    } else {
      return null;
    }
  };

  const handleVendorChange = (e) => {
    const selectedVendorId = e.target.value;
    const selectedVendor = vendors.find(
      (v) => v.serviceID === selectedVendorId
    );
    setVendor(selectedVendor); // Set the selected vendor object
  };

  useEffect(() => {
    console.log("VV", vendor.name);
  }, [vendor]);

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {validUser && (
          <>
            <Row>
              <Col xs="1"></Col>
              <Col xs="10">
                {vendors.length > 0 && (
                  <div className="text-center">
                    <p>
                      <strong>{settings?.app_name}</strong> let's you pay
                      electricity bills on {vendors.length} vendors. Choose your
                      prefered vendor to get started.
                    </p>
                  </div>
                )}
                <div
                  className="my-3"
                  style={{ border: "1px solid darkorange" }}
                >
                  <ListGroup>
                    <ListGroup.Item>
                      {Object.keys(vendor).length > 0 && (
                        <div className="text-center my-2">
                          <img src={vendor.image} width={100} alt="Vendor" />
                        </div>
                      )}

                      <Form.Group controlId="vendorSelect">
                        <Form.Label>Choose a vendor</Form.Label>
                        <Form.Select
                          onChange={handleVendorChange}
                          defaultValue=""
                        >
                          <option disabled value="">
                            Choose a vendor
                          </option>
                          {vendors.map((v, i) => (
                            <option key={i} value={v.serviceID}>
                              {v.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </ListGroup.Item>
                  </ListGroup>
                </div>

                {/* Meter Type Selection */}
                {Object.keys(vendor).length > 0 && (
                  <>
                    <div
                      className="my-3"
                      style={{ border: "1px solid darkorange" }}
                    >
                      <Form.Group controlId="meterType">
                        <Form.Label>Select Meter Type</Form.Label> &nbsp;
                        <Form.Check
                          inline
                          type="switch"
                          label="Prepaid"
                          name="meterType"
                          value="prepaid"
                          checked={packages === "prepaid"}
                          onChange={(e) => setPackages(e.target.value)}
                        />
                        <Form.Check
                          inline
                          type="switch"
                          label="Postpaid"
                          name="meterType"
                          value="postpaid"
                          checked={packages === "postpaid"}
                          onChange={(e) => setPackages(e.target.value)}
                        />
                      </Form.Group>
                    </div>

                    {/* Meter/Account Number Input */}
                    <div
                      className="my-3"
                      style={{ border: "1px solid darkorange" }}
                    >
                      <ListGroup>
                        <ListGroup.Item>
                          <Form.Group controlId="meterNumber">
                            <Form.Label>Enter meter/account number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="123456"
                              value={meterNumber}
                              onChange={(e) => setMeterNumber(e.target.value)}
                            />
                          </Form.Group>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>

                    {/* Verify Meter Button */}
                    <div className="text-center my-3">
                      <Button variant="danger" onClick={verifyMeter}>
                        Verify meter number
                      </Button>
                    </div>
                  </>
                )}

                {/* Meter Details */}
                {Object.keys(meterDetails).length > 0 && (
                  <>
                    <ListGroup className="my-5">
                      {meterDetails.Customer_Name && (
                        <ListGroup.Item>
                          <strong>Customer Name: </strong>
                          {meterDetails.Customer_Name}
                        </ListGroup.Item>
                      )}
                      {meterDetails.Address && (
                        <ListGroup.Item>
                          <strong>Address: </strong>
                          {meterDetails.Address}
                        </ListGroup.Item>
                      )}
                      {meterDetails.District && (
                        <ListGroup.Item>
                          <strong>District: </strong>
                          {meterDetails.District}
                        </ListGroup.Item>
                      )}
                      {meterDetails.Customer_District && (
                        <ListGroup.Item>
                          <strong>District: </strong>
                          {meterDetails.Customer_District}
                        </ListGroup.Item>
                      )}
                      {meterDetails.Meter_Number && (
                        <ListGroup.Item>
                          <strong>Meter Number: </strong>
                          {meterDetails.Meter_Number}
                        </ListGroup.Item>
                      )}
                      {meterDetails.Account_Number && (
                        <ListGroup.Item>
                          <strong>Account Number: </strong>
                          {meterDetails.Account_Number}
                        </ListGroup.Item>
                      )}
                    </ListGroup>

                    {/* Amount Input */}
                    <div
                      className="my-3"
                      style={{ border: "1px solid darkorange" }}
                    >
                      <ListGroup>
                        <ListGroup.Item>
                          <Form.Group controlId="amount">
                            <Form.Label>Amount (₦)</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter amount"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </Form.Group>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>

                    <div className="text-center">
                      <Button
                        variant="primary"
                        onClick={() => setPinModal(true)}
                      >
                        PAY {amount && "₦" + formatNumber(amount)}
                      </Button>
                    </div>
                  </>
                )}

                {/* Purchase Details */}
                {Object.keys(buyDetails).length > 0 && (
                  <>
                    <h1 className="text-center">PURCHASE DETAILS</h1>
                    <ListGroup className="my-3">
                      {buyDetails.customerName && (
                        <ListGroup.Item>
                          <strong>Name: </strong>
                          {buyDetails.customerName}
                        </ListGroup.Item>
                      )}
                      {buyDetails.Name && (
                        <ListGroup.Item>
                          <strong>Name: </strong>
                          {buyDetails.Name}
                        </ListGroup.Item>
                      )}
                      {buyDetails.customerAddress && (
                        <ListGroup.Item>
                          <strong>Address: </strong>
                          {buyDetails.customerAddress}
                        </ListGroup.Item>
                      )}
                      {buyDetails.Address && (
                        <ListGroup.Item>
                          <strong>Address: </strong>
                          {buyDetails.Address}
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <strong>Amount: </strong>
                        {naira}
                        {formatNumber(buyDetails.amount)}
                      </ListGroup.Item>
                      {buyDetails.balance && (
                        <ListGroup.Item>
                          <strong>Balance: </strong>
                          {buyDetails.balance}
                        </ListGroup.Item>
                      )}
                      {buyDetails.purchased_code && (
                        <ListGroup.Item>
                          <strong>PIN: </strong>
                          {getToken(buyDetails.purchased_code)}
                          <Button
                            variant="link"
                            onClick={() =>
                              copy(getToken(buyDetails.purchased_code))
                            }
                          >
                            Copy
                          </Button>
                        </ListGroup.Item>
                      )}
                      {buyDetails.units && (
                        <ListGroup.Item>
                          <strong>Units: </strong>
                          {buyDetails.units}
                        </ListGroup.Item>
                      )}
                      {buyDetails.PurchasedUnits && (
                        <ListGroup.Item>
                          <strong>Purchased Units: </strong>
                          {buyDetails.PurchasedUnits}
                        </ListGroup.Item>
                      )}
                      {buyDetails.tariff && (
                        <ListGroup.Item>
                          <strong>Tariff: </strong>
                          {buyDetails.tariff}
                        </ListGroup.Item>
                      )}
                      {buyDetails.TariffRate && (
                        <ListGroup.Item>
                          <strong>Tariff Rate: </strong>
                          {buyDetails.TariffRate}
                        </ListGroup.Item>
                      )}
                      {buyDetails.MeterCategory && (
                        <ListGroup.Item>
                          <strong>Meter Category: </strong>
                          {buyDetails.MeterCategory}
                        </ListGroup.Item>
                      )}
                      {buyDetails.MeterNumber && (
                        <ListGroup.Item>
                          <strong>Meter Number: </strong>
                          {buyDetails.MeterNumber}
                        </ListGroup.Item>
                      )}
                      {buyDetails.content.transactions.type && (
                        <ListGroup.Item>
                          <strong>Type: </strong>
                          {buyDetails.content.transactions.type}
                        </ListGroup.Item>
                      )}
                      {buyDetails.content.transactions.product_name && (
                        <ListGroup.Item>
                          <strong>Product Name: </strong>
                          {buyDetails.content.transactions.product_name}
                        </ListGroup.Item>
                      )}
                      {buyDetails.content.transactions.status && (
                        <ListGroup.Item>
                          <strong>Status: </strong>
                          {buyDetails.content.transactions.status}
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </>
                )}
              </Col>
              <Col xs="1"></Col>
              <Col xs="12" className="text-center my-5">
                Need some help?
                <Button
                  variant="link"
                  className="text-warning"
                  as={Link}
                  to="https://swapeki.com/contact-us/"
                >
                  <b>Contact support</b>
                </Button>
              </Col>
              <p>&nbsp;</p>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}

      {/* Modal for PIN input */}
      <Modal
        show={pinModal}
        onHide={() => setPinModal(false)}
        backdrop="static"
        dialogClassName="modal-bg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter your PIN to authorize this transaction.</p>
          <Form.Group>
            <Form.Control
              style={{ color: "#333333 !important" }}
              type="password"
              placeholder="Enter PIN"
              maxLength="4"
              inputMode="numeric"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPinModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handlePay(pin);
              setPinModal(false);
            }}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Electricity;
