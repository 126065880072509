import React, { useState, useContext, useEffect } from "react";
import { Context } from "../context/contex";
import SpinnerOverlay from "../components/Spinner";
import { showAlert } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();

  const { apiURL, validUser, loading, setLoading, logOut, settings } = context;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const logIn = async (e) => {
    e.preventDefault();
    //  console.log("Error:", pass);
    if (!email || !password) {
      return showAlert({
        title: "WARNING!",
        text: "Please fill all required fields.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    try {
      setLoading(true);
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "user_login",
          identifier: email,
          pass: password,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        showAlert({
          title: "ERROR!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
        // console.error("Error:", data.error);
      } else {
        localStorage.setItem("swapekis_email_address", data.email);
        localStorage.setItem("swapekis_tx_pin", data.pin);
        window.location.replace("dashboard");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;

      // You can show a custom prompt or trigger the browser's prompt
      const installBtn = document.querySelector("#install-btn");
      installBtn.style.display = "block";

      installBtn.addEventListener("click", () => {
        installBtn.style.display = "none";
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      });
    });
  }, []);

  return (
    <>
      <div className="signup-container">
        {/* Header Section */}
        <div className="signup-header d-flex justify-content-between align-items-center divides">
          <a href="/" className="back-button">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <span className="header-title">
            <a href="https://swapeki.com/">
              <img src="assets/images/logo.png" />
            </a>
          </span>
          {!validUser ? (
            <a href="/register" className="login-link">
              SIGN UP
            </a>
          ) : (
            <button onClick={logOut} className="login-link">
              LOG OUT
            </button>
          )}
        </div>

        <div className="row my-5 d-flex align-items-center">
          <div className="col-md-5 text-center">
            <img src="assets/images/home-img.png" />
          </div>
          <div className="col-md-7 text-center">
            <h1 className="text-white">
              Smart People use {settings?.app_name} To Trade Crypto, Top-up
              Airtime And Data, Pay Bills And Create USD Card!
            </h1>
            <div
              className="mt-4 justify-content-center d-flex"
              style={{ gap: 20 }}
            >
              {!validUser ? (
                <>
                  <a href="/register" className="btn btn-danger">
                    CREATE ACCOUNT
                  </a>
                  <a href="/login" className="btn btn-primary">
                    LOGIN
                  </a>
                </>
              ) : (
                <a href="/dashboard" className="btn btn-primary">
                  GO TO DASHBOARD
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
