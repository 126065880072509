import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "./Pages/Signup";
import Login from "./Pages/Login";
import Otp from "./Pages/otp";
import RecoverPassword from "./Pages/Recover";
import Dashboard from "./Pages/Authed/Dashboard";
import Deposit from "./Pages/Authed/Deposit";
import TopUp from "./Pages/Authed/Top-up";
import Pay from "./Pages/Authed/Pay";
import Electricity from "./Pages/Authed/Electricity";
import TV from "./Pages/Authed/Tv";
import Tx from "./Pages/Authed/Transaction";

import Education from "./Pages/Authed/Education";
import Trade from "./Pages/Authed/Trade";
import TradeCrypto from "./Pages/Authed/Trade-crypto";
import Stats from "./Pages/Authed/Stats";
import Notifications from "./Pages/Authed/Notifications";
import Settings from "./Pages/Authed/Settings";
import More from "./Pages/Authed/More";
import Send from "./Pages/Authed/Send";
import Withdraw from "./Pages/Authed/Withdraw";
import SetPin from "./Pages/SetPin";
import Intro from "./Pages/Intro";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="register" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="verify-email" element={<Otp />} />
        <Route path="recover-password" element={<RecoverPassword />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="deposit" element={<Deposit />} />
        <Route path="top-up" element={<TopUp />} />
        <Route path="pay" element={<Pay />} />
        <Route path="electricity" element={<Electricity />} />
        <Route path="tv" element={<TV />} />
        <Route path="education" element={<Education />} />
        <Route path="trade" element={<Trade />} />
        <Route path="trade-crypto" element={<TradeCrypto />} />
        <Route path="stats" element={<Stats />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="settings" element={<Settings />} />
        <Route path="more" element={<More />} />
        <Route path="send" element={<Send />} />
        <Route path="withdraw" element={<Withdraw />} />
        <Route path="set-pin" element={<SetPin />} />
        <Route path="intro" element={<Intro />} />
        <Route path="transaction" element={<Tx />} />
      </Routes>
    </Router>
  );
}

export default App;
