import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  copy,
  getQueryParam,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card, ListGroup } from "react-bootstrap";
import { Alert } from "bootstrap";

const Tx = () => {
  const [tx, setTx] = useState({});
  const [type, setType] = useState("");

  const context = useContext(Context);

  const { userInfo, validUser, domain, apiURL, settings } = context;
  const id = getQueryParam("tx");
  const getTx = async (id) => {
    if (!id) return;

    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "get_tx_details",
          id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;

      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setType(data.type);
        if (data.payload != null) {
          const p = JSON.parse(data.payload);
          setTx(p);
        }
        //  console.log("Fetched stat:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    getTx(id);
    // console.log("LS", lastSlide);
  }, []);

  const getToken = (str) => {
    const tokenArray = str.split(" ");
    if (tokenArray.length === 3) {
      return tokenArray[2];
    } else if (tokenArray.length === 2) {
      return tokenArray[1];
    }
    if (tokenArray.length === 1) {
      return tokenArray[0];
    } else {
      return null;
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Transaction Details from " + settings?.app_name,
          text: "",
          url: window.location.href,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API not supported in this browser");
    }
  };

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container">
        {/* Greeting and Profile */}
        {Object.keys(tx).length > 0 && (
          <>
            <Row className="align-items-center text-center my-4">
              <Col className="text-white ml-3" xs={12}>
                <i
                  className="fa fa-check-circle text-success"
                  aria-hidden="true"
                  style={{ fontSize: "60px" }}
                ></i>

                <h5>Transaction Completed </h5>
              </Col>
            </Row>

            {/* Expense Card */}

            {Object.keys(tx).length > 0 && (
              <>
                {type == "education" && (
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                      <ListGroup className="mb-3">
                        <ListGroup.Item>
                          <Row>
                            <Col xs={5}>
                              <strong>Name:</strong>
                            </Col>
                            <Col xs={7}>
                              {tx.content.transactions.product_name}
                            </Col>
                          </Row>
                        </ListGroup.Item>

                        {tx.content.transactions.quantity && (
                          <ListGroup.Item>
                            <Row>
                              <Col xs={5}>
                                <strong>Quantity:</strong>
                              </Col>
                              <Col xs={7}>
                                {tx.content.transactions.quantity}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}

                        {tx.content.transactions.unit_price && (
                          <ListGroup.Item>
                            <Row>
                              <Col xs={5}>
                                <strong>Unit Price:</strong>
                              </Col>
                              <Col xs={7}>
                                ₦
                                {formatNumber(
                                  tx.content.transactions.unit_price
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}

                        {tx.content.transactions.convinience_fee !== null && (
                          <ListGroup.Item>
                            <Row>
                              <Col xs={5}>
                                <strong>Convenience Fee:</strong>
                              </Col>
                              <Col xs={7}>
                                ₦
                                {formatNumber(
                                  tx.content.transactions.convinience_fee
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item>
                          <Row>
                            <Col xs={5}>
                              <strong>Total Amount:</strong>
                            </Col>
                            <Col xs={7}>₦{formatNumber(tx.amount)}</Col>
                          </Row>
                        </ListGroup.Item>

                        <ListGroup.Item>
                          <Row>
                            <Col xs={5}>
                              <strong>Status:</strong>
                            </Col>
                            <Col xs={7}>{tx.content.transactions.status}</Col>
                          </Row>
                        </ListGroup.Item>

                        {/* Display PINs */}
                        {tx.tokens &&
                          tx.tokens.length > 0 &&
                          tx.tokens.map((pin, i) => (
                            <ListGroup.Item key={i}>
                              <Row>
                                <Col xs={3}>
                                  <strong>PIN {i + 1}:</strong>
                                </Col>
                                <Col xs={9}>
                                  {pin}
                                  <Button
                                    variant="link"
                                    onClick={() => copy(pin)}
                                  >
                                    <i
                                      className="fa fa-copy"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}

                        {/* Display Cards */}
                        {tx.cards &&
                          tx.cards.length > 0 &&
                          tx.cards.map((pin, i) => (
                            <ListGroup.Item key={i}>
                              <Row>
                                <Col xs={3}>
                                  <strong>Card {i + 1}:</strong>
                                </Col>
                                <Col xs={9}>
                                  <Row>
                                    <Col xs={12}>
                                      Pin: {pin.Pin}
                                      <Button
                                        variant="link"
                                        onClick={() => copy(pin.Pin)}
                                      >
                                        <i
                                          className="fa fa-copy"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    </Col>
                                    <Col xs={12}>
                                      SN: {pin.Serial}
                                      <Button
                                        variant="link"
                                        onClick={() => copy(pin.Serial)}
                                      >
                                        <i
                                          className="fa fa-copy"
                                          aria-hidden="true"
                                        ></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}

                        {/* Purchased Code */}
                        {tx.purchased_code && (
                          <ListGroup.Item>
                            <Row>
                              <Col xs={3}>
                                <strong>PIN:</strong>
                              </Col>
                              <Col xs={7}>{getToken(tx.purchased_code)}</Col>
                              <Col xs={2}>
                                <Button
                                  variant="link"
                                  onClick={() =>
                                    copy(getToken(tx.purchased_code))
                                  }
                                >
                                  <i
                                    className="fa fa-copy"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                )}
                {type == "electricity" && (
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                      <ListGroup className="my-3">
                        {tx.customerName && (
                          <ListGroup.Item>
                            <strong>Name: </strong>
                            {tx.customerName}
                          </ListGroup.Item>
                        )}
                        {tx.Name && (
                          <ListGroup.Item>
                            <strong>Name: </strong>
                            {tx.Name}
                          </ListGroup.Item>
                        )}
                        {tx.customerAddress && (
                          <ListGroup.Item>
                            <strong>Address: </strong>
                            {tx.customerAddress}
                          </ListGroup.Item>
                        )}
                        {tx.Address && (
                          <ListGroup.Item>
                            <strong>Address: </strong>
                            {tx.Address}
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item>
                          <strong>Amount: </strong>
                          {naira}
                          {formatNumber(tx.amount)}
                        </ListGroup.Item>
                        {tx.balance && (
                          <ListGroup.Item>
                            <strong>Balance: </strong>
                            {tx.balance}
                          </ListGroup.Item>
                        )}
                        {tx.purchased_code && (
                          <ListGroup.Item>
                            <strong>PIN: </strong>
                            {getToken(tx.purchased_code)}
                            <Button
                              variant="link"
                              onClick={() => copy(getToken(tx.purchased_code))}
                            >
                              Copy
                            </Button>
                          </ListGroup.Item>
                        )}
                        {tx.units && (
                          <ListGroup.Item>
                            <strong>Units: </strong>
                            {tx.units}
                          </ListGroup.Item>
                        )}
                        {tx.PurchasedUnits && (
                          <ListGroup.Item>
                            <strong>Purchased Units: </strong>
                            {tx.PurchasedUnits}
                          </ListGroup.Item>
                        )}
                        {tx.tariff && (
                          <ListGroup.Item>
                            <strong>Tariff: </strong>
                            {tx.tariff}
                          </ListGroup.Item>
                        )}
                        {tx.TariffRate && (
                          <ListGroup.Item>
                            <strong>Tariff Rate: </strong>
                            {tx.TariffRate}
                          </ListGroup.Item>
                        )}
                        {tx.MeterCategory && (
                          <ListGroup.Item>
                            <strong>Meter Category: </strong>
                            {tx.MeterCategory}
                          </ListGroup.Item>
                        )}
                        {tx.MeterNumber && (
                          <ListGroup.Item>
                            <strong>Meter Number: </strong>
                            {tx.MeterNumber}
                          </ListGroup.Item>
                        )}
                        {tx.content.transactions.type && (
                          <ListGroup.Item>
                            <strong>Type: </strong>
                            {tx.content.transactions.type}
                          </ListGroup.Item>
                        )}
                        {tx.content.transactions.product_name && (
                          <ListGroup.Item>
                            <strong>Product Name: </strong>
                            {tx.content.transactions.product_name}
                          </ListGroup.Item>
                        )}
                        {tx.content.transactions.status && (
                          <ListGroup.Item>
                            <strong>Status: </strong>
                            {tx.content.transactions.status}
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                )}
                {type == "tv" && (
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                      <ListGroup>
                        {tx.content.transactions.name && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Name:</strong>
                              </Col>
                              <Col>{tx.content.transactions.name}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {tx.content.transactions.email && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Email:</strong>
                              </Col>
                              <Col>{tx.content.transactions.email}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {tx.content.transactions.phone && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Phone:</strong>
                              </Col>
                              <Col>{tx.content.transactions.phone}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Product Name:</strong>
                            </Col>
                            <Col>{tx.content.transactions.product_name}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Amount:</strong>
                            </Col>
                            <Col>₦{formatNumber(tx.amount)}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Status:</strong>
                            </Col>
                            <Col>{tx.content.transactions.status}</Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                )}
                {type == "airtime" && (
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                      <ListGroup>
                        {tx.content.transactions.phone && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Phone:</strong>
                              </Col>
                              <Col>{tx.content.transactions.phone}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Product Name:</strong>
                            </Col>
                            <Col>{tx.content.transactions.product_name}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Amount:</strong>
                            </Col>
                            <Col>₦{formatNumber(tx.amount)}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Status:</strong>
                            </Col>
                            <Col>{tx.content.transactions.status}</Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                )}
                {type == "data" && (
                  <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                      <ListGroup>
                        {tx.content.transactions.phone && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Phone:</strong>
                              </Col>
                              <Col>{tx.content.transactions.phone}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Product Name:</strong>
                            </Col>
                            <Col>{tx.content.transactions.product_name}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Amount:</strong>
                            </Col>
                            <Col>₦{formatNumber(tx.amount)}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Status:</strong>
                            </Col>
                            <Col>{tx.content.transactions.status}</Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={2}></Col>
                  </Row>
                )}
              </>
            )}
            <div className="text-center">
              <button
                className="btn btn-outline-warning rounded"
                onClick={handleShare}
              >
                Share
              </button>
            </div>
          </>
        )}
        <Row className="my-5 text-center">
          <Col>
            <Ads />
          </Col>
        </Row>
        <p>&nbsp;</p>
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Tx;
