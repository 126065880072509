import React from "react";

const NoAuth = () => {
  return (
    <>
      <div className="signup-container row">
        <div className="col-md-3"></div>
        <div className="col-md-6 border border-warning">
          <div style={{ fontSize: "100px" }} className="mt-5 text-center">
            <i
              className="fa fa-exclamation-triangle text-warning"
              aria-hidden="true"
            ></i>
          </div>
          <div className="">
            You must be logged into your account to access this page.{" "}
            <a href="/login" className="login-link">
              CLICK HERE TO LOGIN
            </a>{" "}
            or{" "}
            <a href="/register" className="login-link">
              CLICK HERE TO CREATE AN ACCOUNT
            </a>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </>
  );
};

export default NoAuth;
