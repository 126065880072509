import React, { useEffect, useState, useContext } from "react";
import { Context } from "../context/contex";
import SpinnerOverlay from "../components/Spinner";
import { showAlert, presentToast } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import NoAuth from "../components/no-auth";
import axios from "axios";

const SetPin = () => {
  const [otp, setOtp] = useState("");
  const context = useContext(Context);
  const navigate = useNavigate();

  const { apiURL, validUser, loading, setLoading, logOut, userInfo } = context;
  const correctCode = localStorage?.getItem("swapekis_email_code");
  const valid =
    localStorage?.getItem("swapekis_email_code") !== null &&
    localStorage?.getItem("swapekis_email_address") !== null;

  const setPin = async (e) => {
    e.preventDefault();

    if (otp.length !== 4) {
      presentToast(
        "error",
        "top-center",
        "PIN must be exactly 4 digits.",
        () => {}
      );
      return;
    }

    if (isNaN(otp)) {
      presentToast(
        "error",
        "top-center",
        "PIN must be a 4 digits number.",
        () => {}
      );
      return;
    }

    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("action", "set_pin");
    formData.append("pin", otp);
    formData.append("email", userInfo.email);

    try {
      // Correctly send POST request with data and headers
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.success) {
        presentToast(
          "success",
          "top-center",
          "Transaction PIN has been set successfully.",
          () => {
            window.location.replace("dashboard");
          }
        );
      } else {
        console.error("FAILED:", response.data);
        presentToast("error", "top-center", response.data.message, () => {});
      }
    } catch (error) {
      presentToast(
        "error",
        "top-center",
        "An error occurred while changing PIN.",
        () => {}
      );
      console.error("Change PIN error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      <div className="signup-container">
        {/* Header Section */}
        <div className="signup-header d-flex justify-content-between align-items-center divides">
          <a href="/" className="back-button">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <span className="header-title">
            <img src="assets/images/logo.png" />
          </span>
          <button onClick={logOut} className="login-link">
            LOG OUT
          </button>
        </div>
        {validUser && (
          <>
            <div className="text-center my-5">
              <h2 className="custom-title">Set Transaction PIN </h2>
            </div>

            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <form onSubmit={setPin} className="signup-card mx-5 mb-5">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter 4 digit PIN"
                      value={otp}
                      onInput={(e) => setOtp(e.target.value)}
                      maxLength={4}
                      inputMode="numeric"
                    />
                  </div>

                  <button type="submit" className="btn btn-primary btn-block">
                    SET PIN
                  </button>
                </form>
              </div>
              <div className="col-md-3"></div>
            </div>
          </>
        )}
        {!validUser && <NoAuth />}
      </div>
    </>
  );
};

export default SetPin;
