import React, { useState, useContext, useEffect } from "react";
import { Context } from "../context/contex";
import SpinnerOverlay from "../components/Spinner";
import { showAlert, getQueryParam } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ref, setRef] = useState("");

  const context = useContext(Context);
  const navigate = useNavigate();

  const { apiURL, validUser, loading, setLoading, logOut } = context;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const exist = () => {
    //If user is already signed up on this device but did not verify email we can just let them verify it.
    const c = localStorage?.getItem("swapekis_email_code");
    const em = localStorage?.getItem("swapekis_email_address");
    if (c !== null && em !== null) {
      showAlert({
        title: "ACCOUNT FOUND!",
        text: `We found an existing account on this device. Please check ${em} and enter the verification code we sent to that email to continue. You will be redirected now.`,
        icon: "info",
        confirmButtonText: "Ok",
      });

      // console.log("Fetched data:", data);
      setTimeout(() => {
        navigate("/verify-email", { replace: true });
        // window.location.replace("verify-email");
      }, 2000);
    }
  };

  useEffect(() => {
    exist();
  }, []);

  useEffect(() => {
    if (getQueryParam("r") != null) {
      localStorage.setItem("swapeki_ref_by", getQueryParam("r"));
      setRef(getQueryParam("r"));
    } else if (localStorage.getItem("swapeki_ref_by") != null) {
      setRef(localStorage.getItem("swapeki_ref_by"));
    } else {
      return;
    }
  }, []);

  const register = async (e) => {
    e.preventDefault();
    if (
      fname.trim() === "" ||
      lname.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === "" ||
      password.trim() === "" ||
      password2.trim() === ""
    ) {
      return showAlert({
        title: "WARNING!",
        text: "Please fill all required fields.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    if (phone.length < 11) {
      return showAlert({
        title: "WARNING!",
        text: "Phone number cannot be less than 11 digits.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    setLoading(true);

    const regInfo = {
      action: "user_signup",
      first_name: fname,
      last_name: lname,
      email: email,
      phone: phone,
      pass: password,
      pass2: password2,
    };

    if (ref !== "") {
      regInfo.ref_by = ref;
    }

    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(regInfo),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        showAlert({
          title: "ERROR!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
        // console.error("Error:", data.error);
      } else {
        // await storage?.set("vin_auth_token", data.token);
        localStorage.setItem("swapekis_email_code", data.code);
        localStorage.setItem("swapekis_email_address", data.email);
        localStorage.removeItem("swapeki_ref_by");
        // console.log("Fetched data:", data);
        window.location.replace("verify-email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />

      <div className="signup-container">
        {/* Header Section */}
        <div className="signup-header d-flex justify-content-between align-items-center divides">
          <a href="/" className="back-button">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <span className="header-title">
            <a href="https://swapeki.com/">
              <img src="assets/images/logo.png" />
            </a>
          </span>
          {!validUser ? (
            <a href="/login" className="login-link">
              LOGIN
            </a>
          ) : (
            <button onClick={logOut} className="login-link">
              LOG OUT
            </button>
          )}
        </div>
        {!validUser && (
          <div className="text-center my-5">
            <h2 className="custom-title">Create your Account</h2>
          </div>
        )}
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            {!validUser ? (
              <form onSubmit={register} className="signup-card mx-5 mb-5">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={fname}
                    onInput={(e) => setFname(e.target.value)}
                  />
                  <small className="text-danger">
                    MUST be same as the name on your ID documents and bank
                    account.
                  </small>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lname}
                    onInput={(e) => setLname(e.target.value)}
                  />
                  <small className="text-danger">
                    MUST be same as the surname on your ID documents and bank
                    account.
                  </small>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="E-mail"
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    value={phone}
                    onInput={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  />
                  <i
                    className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                <div className="form-group password-field">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    value={password2}
                    onInput={(e) => setPassword2(e.target.value)}
                  />
                  <i
                    className={`fa ${
                      showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                    }`}
                    onClick={toggleConfirmPasswordVisibility}
                  ></i>
                </div>
                <p className="terms">
                  By creating an account you confirm that you have read and
                  accepted our
                  <a
                    target="_blank"
                    href="https://swapeki.com/terms-and-conditions/"
                    className="terms-link"
                  >
                    {" "}
                    Terms of Service
                  </a>
                </p>
                <button type="submit" className="btn btn-primary btn-block">
                  CREATE ACCOUNT
                </button>
              </form>
            ) : (
              <>
                <div style={{ fontSize: "100px" }} className="mt-5 text-center">
                  <i
                    className="fa fa-exclamation-triangle text-warning"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="">
                  You are currently logged in as{" "}
                  {localStorage.getItem("swapekis_email_address")}.{" "}
                  <a href="/dashboard" className="login-link">
                    CLICK HERE TO GO TO YOUR DASHBOARD
                  </a>{" "}
                  or{" "}
                  <button onClick={logOut} className="login-link">
                    CLICK HERE TO LOG OUT
                  </button>{" "}
                  if you wish to login to a different account.
                </div>
              </>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default Signup;
