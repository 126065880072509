import { useEffect, useState, useContext } from "react";

import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { Context } from "../context/contex";

const Footer = () => {
  const [unread, setUnread] = useState(0);
  const context = useContext(Context);
  const { userInfo, transactions, validUser, domain, apiURL } = context;

  useEffect(() => {
    const fetchUnread = async () => {
      if (!validUser) {
        return;
      }

      const formData = new FormData();
      formData.append("email", userInfo?.email);
      formData.append("action", "get_unread_notifications");

      try {
        const response = await axios.post(apiURL, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (response.data.success) {
          //  console.log("UNREAD:", response.data);

          setUnread(response.data.unread);
        } else {
          // console.log("AUNREAD:", response.data);

          setUnread(0);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUnread();
    const interval = setInterval(() => {
      fetchUnread();
    }, 3000);
    return () => clearInterval(interval);
  }, [userInfo]);

  return (
    <div className="footer">
      <div className="footer-item">
        <Link to="/dashboard" className="footer-link">
          <i className="fa fa-home" aria-hidden="true"></i>
          <br />
          <span>Home</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/stats" className="footer-link">
          <i className="fa fa-bar-chart" aria-hidden="true"></i>
          <br />
          <span>Stats</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/deposit" className="footer-link">
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          <br />
          <span>Deposit</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/notifications" className="footer-link">
          <i className="fa fa-bell" aria-hidden="true"></i>
          {unread > 0 && (
            <sup>
              <Badge bg="danger">{unread}</Badge>
            </sup>
          )}
          <br />
          <span>Notifications</span>
        </Link>
      </div>
      <div className="footer-item">
        <Link to="/settings" className="footer-link">
          <i className="fa fa-cog" aria-hidden="true"></i>
          <br />
          <span>Settings</span>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
