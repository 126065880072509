import React, { useEffect, useState, useContext } from "react";
import { Context } from "../context/contex";
import SpinnerOverlay from "../components/Spinner";
import { showAlert, presentToast } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const context = useContext(Context);
  const navigate = useNavigate();

  const { apiURL, validUser, loading, setLoading, logOut, userInfo } = context;
  const correctCode = localStorage?.getItem("swapekis_email_code");
  const valid =
    localStorage?.getItem("swapekis_email_code") !== null &&
    localStorage?.getItem("swapekis_email_address") !== null;

  const compare = (e) => {
    e.preventDefault();
    if (+correctCode === +otp) {
      localStorage?.removeItem("swapekis_email_code");
      showAlert({
        title: "SUCCESS!",
        text: `Your email is now verified.`,
        icon: "success",
        confirmButtonText: "Ok",
      });

      // console.log("Fetched data:", data);
      setTimeout(() => {
        window.location.replace("dashboard");
      }, 2000);
    } else {
      return showAlert({
        title: "ERROR!",
        text: `Your verification code is wrong. Check your email and try again.`,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const resend = async () => {
    if (correctCode == null) {
      showAlert({
        title: "ERROR!",
        text: `Code not found!`,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("action", "resend_v_email");
    formData.append("code", correctCode);
    formData.append("email", userInfo?.email);

    try {
      // Correctly send POST request with data and headers
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.success) {
        presentToast("success", "top-center", response.data.message, () => {});
      } else {
        console.error("FAILED:", response.data);
        presentToast("error", "top-center", response.data.message, () => {});
      }
    } catch (error) {
      presentToast(
        "error",
        "top-center",
        "An error occurred while changing PIN.",
        () => {}
      );
      console.error("Change PIN error:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(correctCode);

  return (
    <>
      <SpinnerOverlay loading={loading} />

      <ToastContainer />
      <div className="signup-container">
        {/* Header Section */}
        <div className="signup-header d-flex justify-content-between align-items-center divides">
          <a href="/" className="back-button">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <span className="header-title">
            <img src="assets/images/logo.png" />
          </span>
          <button onClick={logOut} className="login-link">
            LOG OUT
          </button>
        </div>
        {valid && (
          <div className="text-center my-5">
            <h2 className="custom-title">Verify your email </h2>
            <p className="text-white">
              Enter the verification code we sent to{" "}
              <b>{localStorage.getItem("swapekis_email_address")}</b>
            </p>
          </div>
        )}
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            {valid ? (
              <form onSubmit={compare} className="signup-card mx-5 mb-5">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter OTP"
                    value={otp}
                    onInput={(e) => setOtp(e.target.value)}
                  />
                </div>

                <p className="terms">
                  Didn't get the code? Please check the Spam or Promotion
                  folders or
                  <b
                    onClick={resend}
                    className="terms-link"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Resend code
                  </b>
                </p>
                <button type="submit" className="btn btn-primary btn-block">
                  VERIFY EMAIL
                </button>
              </form>
            ) : (
              <>
                <div style={{ fontSize: "100px" }} className="mt-5 text-center">
                  <i
                    className="fa fa-exclamation-triangle text-warning"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="">
                  Your details could not be found.{" "}
                  <a href="/register" className="login-link">
                    CLICK HERE TO CREATE AN ACCOUNT
                  </a>{" "}
                </div>
              </>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default Otp;
