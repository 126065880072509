import React, { useEffect, useRef, useState, useContext } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import SpinnerOverlay from "../Spinner";

import {
  formatNumber,
  showAlert,
  presentToast,
  naira,
} from "../../utils/helpers";
import "./pay.css";
import { Context } from "../../context/contex";

export const FlutterPay = ({
  amount, //Original amount entered
  grossAmount, //Amount + fees
  customer,
  title,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const context = useContext(Context);

  const { apiURL, settings, loading, setLoading } = context;

  const config = {
    public_key: "FLWPUBK_TEST-69dea5a2962da3e394b1ce8d8b894add-X",
    tx_ref: `tx_${Date.now()}`,
    amount: grossAmount,
    currency: "NGN",
    payment_options: "card,banktransfer,ussd",
    customer: {
      email: customer.email,
      phone_number: customer.phonenumber,
      name: customer.name,
    },
    customizations: {
      title: title,
      description: description,
      logo: "assets/images/logo.png",
    },
  };

  const verifyPayment = async (id, amount, email, our_ref) => {
    setLoading(true);
    try {
      const verifyResponse = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "verify_flw_tx",
          transactionId: String(id),
          email: email,
          amount: String(amount),
          our_ref: our_ref,
        }),
      });

      const verifyResult = await verifyResponse.json();
      if (verifyResult.success) {
        console.log("Transaction verified successfully:", verifyResult);
        return true;
      } else {
        console.log("Transaction verification failed:", verifyResult);
        return false;
      }
    } catch (error) {
      console.error("Error verifying transaction:", error);
    }
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <SpinnerOverlay loading={loading} />

      <ToastContainer />
      <div className="d-grid gap-2">
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            handleFlutterPayment({
              callback: async (response) => {
                //  console.log("FLW RESPONSE", response);
                if (response.status === "successful") {
                  // Send transaction ID to your backend for verification
                  const verify = await verifyPayment(
                    response.transaction_id,
                    amount,
                    customer.email,
                    config.tx_ref
                  );

                  if (verify) {
                    setLoading(false);

                    presentToast(
                      "success",
                      "top-center",
                      `Your ${
                        settings?.app_name
                      } wallet has been funded with ₦${formatNumber(
                        amount.toString()
                      )}`,
                      () => {
                        window.location.href = "/dashboard";
                      }
                    );
                  }
                } else if (response.status === "completed") {
                  setTimeout(async () => {
                    const verify = await verifyPayment(
                      response.transaction_id,
                      amount,
                      customer.email,
                      config.tx_ref
                    );
                    if (verify) {
                      setLoading(false);

                      presentToast(
                        "success",
                        "top-center",
                        `Your ${
                          settings?.app_name
                        } wallet has been funded with ₦${formatNumber(
                          amount.toString()
                        )}`,
                        () => {
                          window.location.href = "/dashboard";
                        }
                      );
                    }
                  }, 2000);
                } else {
                  setLoading(false);

                  console.log("Payment failed:", response);
                  showAlert({
                    title: "Payment Failed!",
                    text: "There was a problem completing your payment. Try again soon.",
                    icon: "error",
                    confirmButtonText: "Ok",
                  });
                }
                closePaymentModal(); // this will close the modal programmatically
              },
              onClose: () => {
                console.log("Payment closed");
              },
            });
          }}
        >
          Pay {naira}
          {formatNumber(grossAmount.toString())}
        </Button>
      </div>
    </>
  );
};
