import { useContext } from "react";

import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card } from "react-bootstrap";

const Pay = () => {
  const context = useContext(Context);

  const { userInfo, validUser } = context;

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container className="three-column-cards-container">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row className="mt-4 text-white">
              <Col className="text-center" size="12">
                <h2 className="my-5">Pay Bills</h2>
              </Col>
            </Row>
            <Row className="mb-4 text-center">
              <Col>
                <Ads />
              </Col>
            </Row>

            <Row>
              <Col md={4} className="mb-4">
                <Card className="hover-zoom card-custom text-center">
                  <Card.Img
                    variant="top"
                    src="assets/images/electricity.png"
                    className="avatar-img mx-auto"
                  />
                  <Card.Body>
                    <Card.Title>Pay Electricity Bill</Card.Title>
                    <Card.Text>
                      Pay for electricity on prepaid and postpaid meters.
                    </Card.Text>
                    <Button as={Link} to="/electricity" variant="primary">
                      PAY NOW
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-4">
                <Card className="hover-zoom card-custom text-center">
                  <Card.Img
                    variant="top"
                    src="assets/images/tv.png"
                    className="avatar-img mx-auto"
                  />
                  <Card.Body>
                    <Card.Title>Pay Cable TV</Card.Title>
                    <Card.Text>
                      Easily renew or change your TV subscription.
                    </Card.Text>
                    <Button as={Link} to="/tv" variant="primary">
                      PAY NOW
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="hover-zoom card-custom text-center">
                  <Card.Img
                    variant="top"
                    src="assets/images/education.png"
                    className="avatar-img mx-auto"
                  />
                  <Card.Body>
                    <Card.Title>Pay Educational Bills</Card.Title>
                    <Card.Text>Pay for WAEC, JAMB and more.</Card.Text>
                    <Button as={Link} to="/education" variant="primary">
                      PAY NOW
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center my-5 text-white">
                Need some help?
                <Button
                  variant="link"
                  className="text-warning"
                  as={Link}
                  to="https://swapeki.com/contact-us/"
                >
                  <b>Contact support</b>
                </Button>
              </Col>
              <p>&nbsp;</p>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Pay;
