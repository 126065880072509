import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
  Alert,
} from "react-bootstrap";

const Withdraw = () => {
  // State management

  const [amount, setAmount] = useState("");
  const [recipient, setRecipient] = useState("");

  const context = useContext(Context);
  const [rInfo, setRInfo] = useState({});
  const [success, setSuccess] = useState(false);
  const [pin, setPin] = useState("");

  const [pinModal, setPinModal] = useState(false);
  const [stat, setStat] = useState([]);

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
    getUserInfo,
    domain,
  } = context;

  const getWithdrawals = async () => {
    if (!userInfo) return;

    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "get_user_withdrawals",
          email: userInfo.email,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;

      if (!data.success) {
        console.error("Error:", data);
      } else {
        setStat(data.withdrawals);
        console.log("Fetched withdraw:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    getWithdrawals();
    // console.log("LS", lastSlide);
  }, [userInfo]);
  const handleWithdraw = async (pin) => {
    if (userInfo.tx_pin === null) {
      presentToast(
        "error",
        "top-center",
        "Please set your transaction PIN.",
        () => {
          window.location.replace("set-pin");
        }
      );

      return;
    }

    if (!amount || parseFloat(amount) === 0) {
      presentToast("error", "top-center", "Invalid amount.", () => {});

      return;
    }

    if (+amount > +userInfo?.ngn_balance) {
      presentToast(
        "error",
        "top-center",
        "You do not have sufficient funds in your wallet.",
        () => {
          window.location.replace("deposit");
        }
      );

      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("error", "top-center", "Incorrect PIN.", () => {});

      return;
    }
    setLoading(true);
    try {
      // Prepare data as URLSearchParams
      const params = new URLSearchParams();
      params.append("account_number", userInfo.withdrawal_bank_account);
      params.append("account_name", userInfo.withdrawal_bank_name);
      params.append("bank", userInfo.withdrawal_bank);
      params.append("amount", +amount - +settings?.withdrawal_fee);
      params.append("email", userInfo?.email);
      params.append("gross", amount);
      params.append("action", "fund_withdrawal");

      const response = await axios.post(apiURL, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      console.log("Response:", response);

      if (!response.data.success) {
        presentToast("error", "top-center", response.data.message, () => {});
      } else {
        await getUserInfo();
        presentToast("success", "top-center", response.data.message, () => {});
      }
    } catch (error) {
      console.log("Error:", error);
      presentToast("error", "top-center", "Something went wrong...", () => {});
    }
    setLoading(false);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <div className="text-center">
              <h2 className="mb-4">Send Money</h2>
              <h4 className="mb-4">
                Current balance: {naira}
                {formatNumber(userInfo?.ngn_balance)}
              </h4>
            </div>

            <Row className="my-4 text-center">
              <Col>
                <Ads />
              </Col>
            </Row>

            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                {userInfo?.withdrawal_bank_account !== null ? (
                  <Row className="mb-4">
                    <Col md={12}>
                      <Form>
                        <Form.Group
                          controlId="formConfirmPassword"
                          className="mb-3 form-group"
                        >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="How much to withdraw"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </Form.Group>
                        {amount && (
                          <div className="text-center">
                            <Button
                              variant="primary"
                              type="button"
                              onClick={() => setPinModal(true)}
                            >
                              Withdraw {naira}
                              {formatNumber(amount)}
                            </Button>
                          </div>
                        )}
                      </Form>
                    </Col>
                  </Row>
                ) : (
                  <div className="text-center p-4">
                    <img src="assets/images/nothing.png" height={80} />
                    <p>
                      Please go to{" "}
                      <a href="/settings" className="text-warning">
                        Settings page
                      </a>{" "}
                      to link a bank.
                    </p>
                  </div>
                )}
                {/* Recent Transactions */}
                <Row className="align-items-center">
                  <Col xs="4">
                    <h6>DATE</h6>
                  </Col>
                  <Col xs="4">
                    <h6>AMOUNT</h6>
                  </Col>
                  <Col xs="4">
                    <h6>STATUS</h6>
                  </Col>
                </Row>
                {stat.length > 0 ? (
                  <div className="recent-transactions mt-4">
                    <ListGroup variant="flush" className="mt-3">
                      {stat.map((tx, index) => (
                        <ListGroup.Item
                          key={`tx_${index}`}
                          className="d-flex justify-content-between"
                          style={{ borderBottom: "1px yellow dotted" }}
                        >
                          <div>
                            <h6>{formatLocalTime(tx.add_date)}</h6>
                          </div>
                          <div>
                            <h6>
                              {naira}
                              <b>{formatNumber(tx.gross)}</b>
                            </h6>
                          </div>
                          <div>
                            {tx.is_approved === 1 ? (
                              <b className="text-success">Completed</b>
                            ) : (
                              <b className="text-warning">Pending</b>
                            )}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                ) : (
                  <div className="text-center p-4">
                    <img src="assets/images/fly.gif" height={80} />
                    <p>No withdrawals found.</p>
                  </div>
                )}
              </Col>
              <Col md={2}></Col>
            </Row>

            <Row className="mb-4">
              <Col xs="12" className="text-center my-5">
                Need some help?
                <Button
                  variant="link"
                  className="text-warning"
                  as={Link}
                  to="https://swapeki.com/contact-us/"
                >
                  <b>Contact support</b>
                </Button>
              </Col>
              <p>&nbsp;</p>
            </Row>

            <Modal
              show={pinModal}
              onHide={() => setPinModal(false)}
              backdrop="static"
              dialogClassName="modal-bg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Authorize Transaction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Enter your PIN to authorize this transaction.</p>
                <Form.Group>
                  <Form.Control
                    style={{ color: "#333333 !important" }}
                    type="password"
                    placeholder="Enter PIN"
                    maxLength="4"
                    inputMode="numeric"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setPinModal(false)}>
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    handleWithdraw(pin);
                    setPinModal(false);
                  }}
                >
                  Proceed
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Withdraw;
