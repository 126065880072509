import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
  copy,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";

const TV = () => {
  const [vendors, setVendors] = useState([]);
  const [tvVariations, setTvVariations] = useState({});
  const [decDetails, setDecDetails] = useState({});

  const [vendor, setVendor] = useState({});
  const [meterDetails, setMeterDetails] = useState({});
  const [buyDetails, setBuyDetails] = useState({});
  const [decNumber, setDecNumber] = useState("");

  const [packages, setPackages] = useState({});
  const [meterNumber, setMeterNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("renew");
  const context = useContext(Context);
  const [pin, setPin] = useState("");

  const [pinModal, setPinModal] = useState(false);

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
  } = context;

  const fetchServiceID = async (identifier) => {
    setLoading(true);
    try {
      const response = await fetch(
        vtpassBase + `services?identifier=${identifier}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("VENDOR", result.content);
        setLoading(false);

        return result.content;
      } else {
        setLoading(false);

        return null;
      }
    } catch (error) {
      console.error("Error fetching service ID:", error);
      setLoading(false);

      return null;
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const as = await fetchServiceID("tv-subscription");
        //   const ds = await fetchServiceID("data");

        if (as) {
          setVendors(as);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  }, [vtpassBase, vtpassApiKey, vtpassPubKey]);

  const getVariations = async (identifier) => {
    setLoading(true);
    setDecDetails({});
    setBuyDetails({});
    setPackages({});
    try {
      const response = await fetch(
        vtpassBase + `service-variations?serviceID=${identifier}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("VAR", result.content.varations);
        setTvVariations(result.content.varations);
        setLoading(false);
      } else {
        console.error("Error fetching service variations:", result);
        setLoading(false);

        return null;
      }
    } catch (error) {
      console.error("Error getting variations:", error);
      setLoading(false);

      return null;
    }
  };

  useEffect(() => {
    if (!vendor.serviceID) {
      return;
    }
    getVariations(vendor.serviceID);
  }, [vendor.serviceID]);

  const verifyDecNumber = async () => {
    if (!decNumber) {
      presentToast(
        "error",
        "top-center",
        `Smartcard number is empty.`,
        () => {}
      );

      return;
    }
    if (Object.keys(vendor).length === 0) {
      presentToast("error", "top-center", `Please choose a vendor.`, () => {});

      return;
    }
    setDecDetails({});
    setBuyDetails({});
    setLoading(true);
    const data = {
      serviceID: vendor.serviceID,

      billersCode: String(decNumber),
    };
    try {
      const response = await fetch(vtpassBase + `merchant-verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.code === "000" && !result.content.error) {
        console.log("verified", result);
        setDecDetails(result.content);
        setLoading(false);
      } else {
        console.log("not verified", result);
        setLoading(false);
        presentToast(
          "error",
          "top-center",
          `${result.content.error}`,
          () => {}
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handlePay = async (pin) => {
    if (userInfo.tx_pin === null) {
      presentToast(
        "warning",
        "top-center",
        `Please set your transaction PIN first.`,
        () => {
          window.location.replace("set-pin");
        }
      );

      return;
    }

    if (Object.keys(vendor).length === 0) {
      presentToast(
        "warning",
        "top-center",
        `Please choose a vendor.`,
        () => {}
      );

      return;
    }
    if (Object.keys(packages).length === 0) {
      presentToast(
        "warning",
        "top-center",
        `Please choose a package.`,
        () => {}
      );

      return;
    }

    if (Object.keys(decDetails).length === 0 && vendor.serviceID != "showmax") {
      presentToast(
        "warning",
        "top-center",
        `Please verify smartcard number first.`,
        () => {}
      );

      return;
    }

    if (+packages.variation_amount > +vtpBalance) {
      presentToast(
        "warning",
        "top-center",
        `Service not available at the moment.`,
        () => {}
      );

      return;
    }
    if (+packages.variation_amount > +userInfo?.ngn_balance) {
      presentToast(
        "warning",
        "top-center",
        `You do not have sufficient funds in your wallet.`,
        () => {
          window.location.replace("deposit");
        }
      );
      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("warning", "top-center", `Incorrect PIN!`, () => {});
      return;
    }
    setBuyDetails({});
    const id = generateRequestId();
    setLoading(true);
    const data = {
      serviceID: vendor.serviceID,
      amount: +packages.variation_amount,
      request_id: id,
      billersCode: decNumber,
      variation_code: packages.variation_code,
      phone: userInfo.phone,
      subscription_type: type,
    };

    try {
      const response = await fetch(vtpassBase + "pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.code === "000" || result.code === "099") {
        console.log("TV purchase successful:", result);
        requeryTransaction(id);
      } else {
        presentToast(
          "error",
          "top-center",
          `TV purchase failed: ${result.response_description}`,
          () => {}
        );

        console.log("TV top-up failed:", result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error top-up:", error);
      setLoading(false);
    }
  };

  const requeryTransaction = async (requestId) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${vtpassBase}requery`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "secret-key": vtpassSecKey,
          },
          body: JSON.stringify({ request_id: requestId }),
        });

        const result = await response.json();
        if (
          result.code === "000" &&
          result.content.transactions.status === "delivered"
        ) {
          console.log("Transaction delivered:", result);
          clearInterval(interval);

          const response = await fetch(apiURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              action: "record_tx_and_debit",
              title: result.content.transactions.product_name,
              desc: result.content.transactions.product_name,
              amount: (
                +result.content.transactions.amount +
                +result.content.transactions.convinience_fee
              ).toString(),
              ref: result.content.transactions.transactionId,
              our_ref: requestId,
              status: "Complete",
              email: String(userInfo?.email),
              payload: JSON.stringify(result),
              type: "tv",
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          setBuyDetails(result);
          setVendor({});
          setDecDetails({});
          setPackages({});
          setTvVariations({});
          await context.getUserInfo();
          await context.getTransactions();
          setLoading(false);
          presentToast(
            "success",
            "top-center",
            `Your TV subscription was successful.`,
            () => {}
          );
        } else {
          presentToast(
            "error",
            "top-center",
            `${result.content.transactions.status}`,
            () => {}
          );

          console.log(
            "Transaction status:",
            result.content.transactions.status
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error re-querying transaction:", error);
        clearInterval(interval);
        setLoading(false);
      }
    }, 2000);
  };

  const getToken = (str) => {
    const tokenArray = str.split(" ");
    if (tokenArray.length === 3) {
      return tokenArray[2];
    } else if (tokenArray.length === 2) {
      return tokenArray[1];
    }
    if (tokenArray.length === 1) {
      return tokenArray[0];
    } else {
      return null;
    }
  };

  const handleVendorChange = (e) => {
    setTvVariations({});
    setDecDetails({});
    setBuyDetails({});
    setPackages({});
    const selectedVendorId = e.target.value;
    const selectedVendor = vendors.find(
      (v) => v.serviceID === selectedVendorId
    );
    setVendor(selectedVendor); // Set the selected vendor object
  };

  const handleVarChange = (e) => {
    const selectedVendorId = e.target.value;
    const selectedVendor = tvVariations.find(
      (v) => v.variation_code === selectedVendorId
    );
    setPackages(selectedVendor);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {validUser && (
          <>
            <Row>
              <Col xs="1"></Col>
              <Col xs="10">
                {vendors.length > 0 && (
                  <div className="text-center">
                    <p>
                      <strong>{settings?.app_name}</strong> let's you pay for TV
                      subscription on {vendors.length} vendors. Choose your
                      prefered vendor to get started.
                    </p>
                  </div>
                )}
                <div
                  className="my-3"
                  style={{ border: "1px solid darkorange" }}
                >
                  <ListGroup>
                    <ListGroup.Item>
                      {Object.keys(vendor).length > 0 && (
                        <div className="text-center my-2">
                          <img src={vendor.image} width={100} alt="Vendor" />
                        </div>
                      )}

                      <Form.Group controlId="vendorSelect">
                        <Form.Label>Choose a vendor</Form.Label>
                        <Form.Select
                          onChange={handleVendorChange}
                          defaultValue=""
                        >
                          <option disabled value="">
                            Choose a vendor
                          </option>
                          {vendors.map((v, i) => (
                            <option key={i} value={v.serviceID}>
                              {v.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </ListGroup.Item>
                  </ListGroup>
                </div>

                {tvVariations.length > 0 && (
                  <ListGroup
                    className="mb-3"
                    style={{ border: "1px solid darkorange" }}
                  >
                    <ListGroup.Item>
                      <Form.Group controlId="packageSelect">
                        <Form.Label>Choose package</Form.Label>
                        <Form.Select onChange={handleVarChange} defaultValue="">
                          <option value="">Choose package</option>
                          {tvVariations.map((plan, index) => (
                            <option key={index} value={plan.variation_code}>
                              {plan.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </ListGroup.Item>
                  </ListGroup>
                )}

                {tvVariations.length > 0 && vendor.serviceID !== "showmax" && (
                  <>
                    <ListGroup
                      className="mb-3"
                      style={{ border: "1px solid darkorange" }}
                    >
                      <ListGroup.Item>
                        <Form.Group>
                          <Form.Label>
                            {`Enter ${
                              vendor.name != null && getFirstWord(vendor?.name)
                            } Smart Card number`}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="123456"
                            value={decNumber}
                            onChange={(e) => setDecNumber(e.target.value)}
                          />
                        </Form.Group>
                      </ListGroup.Item>
                    </ListGroup>
                    <div className="text-center">
                      <Button variant="danger" onClick={verifyDecNumber}>
                        Verify smartcard number
                      </Button>
                    </div>
                  </>
                )}

                {/* Display DEC details */}
                {Object.keys(decDetails).length > 0 &&
                  vendor.serviceID !== "showmax" && (
                    <>
                      <ListGroup>
                        {decDetails.Customer_Name && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Customer Name:</strong>
                              </Col>
                              <Col>{decDetails.Customer_Name}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Customer_Number && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Customer Number:</strong>
                              </Col>
                              <Col>{decDetails.Customer_Number}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Customer_Type && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Customer Type:</strong>
                              </Col>
                              <Col>{decDetails.Customer_Type}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Current_Bouquet && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Current Bouquet:</strong>
                              </Col>
                              <Col>{decDetails.Current_Bouquet}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Current_Bouquet_Code && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Current Bouquet Code:</strong>
                              </Col>
                              <Col>{decDetails.Current_Bouquet_Code}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Due_Date && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Due Date:</strong>
                              </Col>
                              <Col>{decDetails.Due_Date}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Renewal_Amount && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Renewal Amount:</strong>
                              </Col>
                              <Col>
                                ₦{formatNumber(decDetails.Renewal_Amount)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Status && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Status:</strong>
                              </Col>
                              <Col>{decDetails.Status}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Smartcard_Number && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Smartcard Number:</strong>
                              </Col>
                              <Col>{decDetails.Smartcard_Number}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                        {decDetails.Balance && (
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <strong>Balance:</strong>
                              </Col>
                              <Col>₦{decDetails.Balance}</Col>
                            </Row>
                          </ListGroup.Item>
                        )}
                      </ListGroup>

                      {/* Radio Buttons for bouquet actions */}
                      <Form
                        className="mb-3"
                        style={{ border: "1px solid darkorange" }}
                      >
                        <Form.Group>
                          <Form.Label>
                            What would you like to do? &nbsp;
                          </Form.Label>
                          <Form.Check
                            inline
                            type="switch"
                            label="Renew Current Bouquet"
                            name="bouquetOptions"
                            value="renew"
                            checked={type === "renew"}
                            onChange={(e) => setType(e.target.value)}
                          />
                          <Form.Check
                            inline
                            type="switch"
                            label="Change Current Bouquet"
                            name="bouquetOptions"
                            value="change"
                            checked={type === "change"}
                            onChange={(e) => setType(e.target.value)}
                          />
                        </Form.Group>
                      </Form>
                    </>
                  )}

                {/* Show phone input if the vendor is "showmax" */}
                {Object.keys(packages).length > 0 &&
                  vendor.serviceID === "showmax" && (
                    <ListGroup
                      className="mb-3"
                      style={{ border: "1px solid darkorange" }}
                    >
                      <ListGroup.Item>
                        <Form.Group>
                          <Form.Label>
                            {`Enter ${
                              vendor.name != null && getFirstWord(vendor?.name)
                            } phone number`}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="123456"
                            value={decNumber}
                            onChange={(e) => setDecNumber(e.target.value)}
                          />
                        </Form.Group>
                      </ListGroup.Item>
                    </ListGroup>
                  )}

                {/* Pay button */}
                {Object.keys(packages).length > 0 &&
                  Object.keys(decDetails).length > 0 &&
                  vendor.serviceID !== "showmax" && (
                    <>
                      <Button
                        variant="primary"
                        className="my-3"
                        onClick={() => setPinModal(true)}
                      >
                        PAY {"₦" + formatNumber(packages.variation_amount)}
                      </Button>
                    </>
                  )}

                {Object.keys(packages).length > 0 &&
                  vendor.serviceID === "showmax" &&
                  decNumber && (
                    <>
                      <Button
                        variant="primary"
                        className="my-3"
                        onClick={() => setPinModal(true)}
                      >
                        PAY {"₦" + formatNumber(packages.variation_amount)}
                      </Button>
                    </>
                  )}

                {/* Purchase Details */}
                {Object.keys(buyDetails).length > 0 && (
                  <>
                    <h1 className="text-center">PURCHASE DETAILS</h1>
                    <ListGroup>
                      {buyDetails.content.transactions.name && (
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Name:</strong>
                            </Col>
                            <Col>{buyDetails.content.transactions.name}</Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                      {buyDetails.content.transactions.email && (
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Email:</strong>
                            </Col>
                            <Col>{buyDetails.content.transactions.email}</Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                      {buyDetails.content.transactions.phone && (
                        <ListGroup.Item>
                          <Row>
                            <Col>
                              <strong>Phone:</strong>
                            </Col>
                            <Col>{buyDetails.content.transactions.phone}</Col>
                          </Row>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <strong>Product Name:</strong>
                          </Col>
                          <Col>
                            {buyDetails.content.transactions.product_name}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <strong>Amount:</strong>
                          </Col>
                          <Col>₦{formatNumber(buyDetails.amount)}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <strong>Status:</strong>
                          </Col>
                          <Col>{buyDetails.content.transactions.status}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </>
                )}
              </Col>
              <Col xs="1"></Col>
              <Col xs="12" className="text-center my-5">
                Need some help?
                <Button
                  variant="link"
                  className="text-warning"
                  as={Link}
                  to="https://swapeki.com/contact-us/"
                >
                  <b>Contact support</b>
                </Button>
              </Col>
              <p>&nbsp;</p>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}

      {/* Modal for PIN input */}
      <Modal
        show={pinModal}
        onHide={() => setPinModal(false)}
        backdrop="static"
        dialogClassName="modal-bg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter your PIN to authorize this transaction.</p>
          <Form.Group>
            <Form.Control
              style={{ color: "#333333 !important" }}
              type="password"
              placeholder="Enter PIN"
              maxLength="4"
              inputMode="numeric"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPinModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handlePay(pin);
              setPinModal(false);
            }}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TV;
