import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  copy,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card, ListGroup } from "react-bootstrap";
import { Alert } from "bootstrap";

const Notifications = () => {
  const [alert, setAlert] = useState({
    notifications: [],
  });

  const context = useContext(Context);

  const { userInfo, transactions, validUser, domain, apiURL } = context;

  const getAlert = async () => {
    if (!userInfo) return;

    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "get_user_notifications",
          email: userInfo.email,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;

      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setAlert(data);
        //  console.log("Fetched stat:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    getAlert();
    // console.log("LS", lastSlide);
  }, [userInfo]);

  const updateRead = async () => {
    if (!userInfo) return;

    try {
      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "update_read_notifications",
          email: userInfo.email,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;

      if (data.error) {
        console.error("Error:", data.error);
      } else {
        //  console.log("Fetched stat:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    updateRead();
  }, []);

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row className="align-items-center text-center my-4">
              <Col className="text-white ml-3" xs={12}>
                <h5> </h5>
                <h2>{userInfo?.first_name.toUpperCase()}'s NOTIFICATIONS</h2>
              </Col>
            </Row>

            {/* Expense Card */}
            <Row className="my-4 text-center">
              <Col>
                <Ads />
              </Col>
            </Row>

            {alert["notifications"].length > 0 ? (
              <div className="recent-transactions mt-4">
                <ListGroup variant="flush" className="mt-3">
                  {alert["notifications"].map((tx, index) => (
                    <ListGroup.Item
                      key={`tx_${index}`}
                      className="d-flex justify-content-between"
                      style={{ borderBottom: "1px yellow dotted" }}
                    >
                      <div>
                        <h6>{tx.text}</h6>
                        <br />
                        <small>{formatLocalTime(tx.add_date)}</small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            ) : (
              <div className="text-center p-4">
                <img src="assets/images/fly.gif" height={80} />
                <p>No notifications found.</p>
              </div>
            )}
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Notifications;
