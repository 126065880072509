import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";

const TradeCrypto = () => {
  const [type, setType] = useState("airtime");
  const context = useContext(Context);
  const amounts = [100, 200, 500, 1000, 5000, 10000];

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
  } = context;

  const handleTab = (type) => {
    setType(type);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row>
              <Col xs="1"></Col>
              <Col xs="10">
                <Row>
                  <Col className="text-center" size="12">
                    <h2 className="mb-5">Trade Crypto Assets with Naira</h2>
                  </Col>
                  <Col xs="12" className="text-center">
                    <Tabs
                      defaultActiveKey="sell"
                      id="justify-tab-example"
                      className="mb-3"
                      justify
                      onSelect={(selectedKey) => handleTab(selectedKey)} // Use onSelect here
                    >
                      <Tab eventKey="sell" title="SELL CRYPTO">
                        <Row>
                          <Col xs={12} className="text-center">
                            <iframe
                              src="https://www.flincap.com/swapeki"
                              style={{
                                width: "100%",
                                height: "600px",
                                background: "#f7f7f7",
                              }}
                            ></iframe>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="buy" title="BUY CRYPTO">
                        <Container className="my-5">
                          {/* Data Service Grid */}
                          <Row>
                            <Col
                              xs={12}
                              className="text-center border border-warning"
                            >
                              <div
                                style={{ fontSize: "100px" }}
                                className="mt-5 text-center"
                              >
                                <i
                                  className="fa fa-exclamation-triangle text-warning"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="">
                                Buying crypto is still in the works but would be
                                available soon. Please check back some other
                                time.
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Tab>
                    </Tabs>
                  </Col>

                  <Col xs="12" className="text-center my-5">
                    Need some help?
                    <Button
                      variant="link"
                      className="text-warning"
                      as={Link}
                      to="https://swapeki.com/contact-us/"
                    >
                      <b>Contact support</b>
                    </Button>
                  </Col>
                  <p>&nbsp;</p>
                </Row>
              </Col>
              <Col xs="1"></Col>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default TradeCrypto;
