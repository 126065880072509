import { useContext } from "react";

import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card } from "react-bootstrap";

const More = () => {
  const context = useContext(Context);

  const { userInfo, validUser, settings } = context;

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container className="three-column-cards-container">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row className="mt-4 text-white">
              <Col className="text-center" size="12">
                <h2 className="my-5">What do you want to do?</h2>
              </Col>
            </Row>
            <Row className="mb-4 text-center">
              <Col>
                <Ads />
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-4">
                <Card className="hover-zoom card-custom text-center">
                  <Card.Img
                    variant="top"
                    src="assets/images/send.png"
                    className="avatar-img mx-auto"
                  />
                  <Card.Body>
                    <Card.Title>Send Money</Card.Title>
                    <Card.Text>
                      You can send money from your wallet to other{" "}
                      {settings?.app_name} users for free.
                    </Card.Text>
                    <Button as={Link} to="/send" variant="primary">
                      SEND MONEY
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} className="mb-4">
                <Card className="hover-zoom card-custom text-center">
                  <Card.Img
                    variant="top"
                    src="assets/images/bankacct.png"
                    className="avatar-img mx-auto"
                  />
                  <Card.Body>
                    <Card.Title>Withdraw Money</Card.Title>
                    <Card.Text>
                      Withdraw funds from your {settings?.app_name} wallet to
                      your linked bank account.
                    </Card.Text>
                    <Button as={Link} to="/withdraw" variant="primary">
                      WITHDRAW NOW
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="text-center my-5 text-white">
                Need some help?
                <Button
                  variant="link"
                  className="text-warning"
                  as={Link}
                  to="https://swapeki.com/contact-us/"
                >
                  <b>Contact support</b>
                </Button>
              </Col>
              <p>&nbsp;</p>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default More;
