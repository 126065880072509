import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MySwal = withReactContent(Swal);

export const showAlert = ({ title, text, icon, confirmButtonText }) => {
  return MySwal.fire({
    title,
    text,
    icon,
    confirmButtonText,
  });
};

export function getPercentage(number, percent) {
  return (number * percent) / 100;
}

export const formatNumber = (number) => {
  return Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // convert timestamp to milliseconds
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    // timeZoneName: "short",
  };
  return date.toLocaleString(undefined, options);
};

export const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const searchArray = (likedByUsers, wallet) => {
  if (!likedByUsers) return false;
  const user = likedByUsers.find(
    (user) => user.wallet === wallet && user.is_active === 1
  );
  return !!user;
};

export const searchArrayBool = (likedByUsers, wallet) => {
  if (!likedByUsers) return false;
  const user = likedByUsers.find((user) => user.wallet === wallet);
  return !!user;
};

export const getPaginatedData = (data, currentPage, itemsPerPage) => {
  const start = currentPage * itemsPerPage;
  const end = start + itemsPerPage;
  return data.slice(start, end);
};

export const generateNonce = () => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

export const copy = (text) => {
  if (text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(
        () => {
          //  console.log("Token copied to clipboard!");
          alert(`Copied to clipboard`);
        },
        (err) => {
          alert(`Could not copy text: ${err}`);
        }
      );
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        alert(`Copied to clipboard`);
      } catch (err) {
        alert(`Could not copy text: ${err}`);
      }
      document.body.removeChild(textArea);
    }
  }
};

export const removeDecimals = (num) => {
  return Number(num).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const presentToast = (
  type = "default", // Type of toast (e.g., success, error, info)
  position = "top-center", // Toast position
  message,
  func, // Callback function on close
  duration = 6000
) => {
  // Dynamically call the toast method based on the type
  toast[type](message, {
    position, // Position options: top-right, top-center, top-left, bottom-right, bottom-center, bottom-left
    onClose: func, // Function to execute on toast close
    autoClose: duration, // Duration before auto-close
    className: `toast-${type}`, // Custom styling based on type (optional)
  });
};

export const naira = "₦";

export const Greeting = () => {
  const getGreeting = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 12) {
      return "Good morning";
    } else if (hour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  return <div>{getGreeting()}</div>;
};

export const txColor = (status) => {
  let txClass = "failed";
  switch (status) {
    case "Complete":
      txClass = "text-success";
      break;
    case "Failed":
      txClass = "text-danger";
      break;
    case "Pending":
      txClass = "text-warning";
      break;
  }
  return txClass;
};

export const getFirstWord = (str) => {
  // Split the string by spaces and return the first element
  return str.split(" ")[0];
};

export const generateRequestId = () => {
  const now = new Date();
  const gmtPlusOneOffset = 60; // GMT+1 offset in minutes
  const gmtPlusOneDate = new Date(now.getTime() + gmtPlusOneOffset * 60 * 1000);

  const year = gmtPlusOneDate.getUTCFullYear();
  const month = String(gmtPlusOneDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(gmtPlusOneDate.getUTCDate()).padStart(2, "0");
  const hour = String(gmtPlusOneDate.getUTCHours()).padStart(2, "0");
  const minutes = String(gmtPlusOneDate.getUTCMinutes()).padStart(2, "0");

  return `${year}${month}${day}${hour}${minutes}_swapeki`;
};
