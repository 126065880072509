import { useEffect, useRef, useState, useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import { Context } from "../context/contex";

const Ads = () => {
  const context = useContext(Context);
  const [ads, setAds] = useState([]);

  const { apiURL } = context;

  const getAds = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_ads" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setAds(data);

        //   console.log("Fetched settings:", data);
      }
    } catch (error) {
      console.error("Error:", error?.message);
    }
  };

  useEffect(() => {
    getAds();
    // console.log("LS", lastSlide);
  }, []);

  return (
    <div>
      {ads && ads.length > 0 && (
        <Carousel fade={true} controls={false} indicators={true}>
          {ads.map((card, index) => (
            <Carousel.Item key={`slide_${index}`}>
              <div className="slide-content">
                <a href={card.link}>
                  {" "}
                  <img src={card.image} className="image1" />
                </a>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default Ads;
