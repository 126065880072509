import React, { useState, useContext } from "react";
import { Context } from "../context/contex";
import SpinnerOverlay from "../components/Spinner";
import { showAlert, presentToast } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const RecoverPassword = () => {
  const [identifier, setIdentifier] = useState("");
  const [correct, setCorrect] = useState(false);
  const [canVerify, setCanVerify] = useState(false);

  const [otp, setOtp] = useState("");

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const context = useContext(Context);
  const navigate = useNavigate();

  const { apiURL, validUser, loading, setLoading, logOut } = context;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const getResetCode = async (e) => {
    e.preventDefault();

    if (!identifier) {
      presentToast(
        "warning",
        "top-center",
        "Please provide your email, phone number or username.",
        () => {}
      );
      return;
    }

    localStorage.removeItem("swapekis_reset_code");
    localStorage.removeItem("swapekis_reset_email_address");

    try {
      setLoading(true);

      const response = await axios.post(
        apiURL,
        new URLSearchParams({
          action: "get_pass_reset_code",
          identifier: identifier,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;
      console.log("Error:", data);
      if (!data.success) {
        showAlert({
          title: "ERROR!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        localStorage.setItem("swapekis_reset_code", data.code);
        localStorage.setItem("swapekis_reset_email_address", data.email);
        presentToast("success", "top-center", data.message, () => {});
        setCanVerify(true);
        console.log("CODE", data.code);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
    setLoading(false);
  };

  const compare = (e) => {
    e.preventDefault();
    const correctCode = localStorage?.getItem("swapekis_reset_code");
    if (+correctCode === +otp) {
      presentToast("success", "top-center", "Set a new password.", () => {});
      setCorrect(true);
    } else {
      return presentToast(
        "error",
        "top-center",
        "Your verification code is wrong. Check your email and try again.",
        () => {}
      );
    }
  };

  //  console.log(correctCode);

  const Resend = async () => {
    if (
      localStorage.getItem("swapekis_reset_email_address") === null &&
      localStorage.getItem("swapekis_email_address") === null
    ) {
      return presentToast(
        "error",
        "top-center",
        "For some reason we could not find your email address... Please reload this page to start over.",
        () => {}
      );
    }
    if (localStorage.getItem("swapekis_reset_code") === null) {
      presentToast(
        "error",
        "top-center",
        "For some reason we could not find your verification code... Please reload this page to start over.",
        () => {}
      );

      return;
    }
    try {
      setLoading(true);
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "resend_v_email",
          code: localStorage.getItem("swapekis_reset_code"),
          email:
            localStorage.getItem("swapekis_reset_email_address") ||
            localStorage.getItem("swapekis_email_address"),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        presentToast("error", "top-center", data.message, () => {});
        // console.error("Error:", data.error);
      } else {
        presentToast("success", "top-center", data.message, () => {});
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const setNewPassword = async (e) => {
    e.preventDefault();
    if (!password || !password2) {
      presentToast(
        "error",
        "top-center",
        "Please enter your new password.",
        () => {}
      );
      return;
    }
    if (password !== password2) {
      presentToast(
        "error",
        "top-center",
        "Both passwords do not match.",
        () => {}
      );
      return;
    }
    if (
      localStorage.getItem("swapekis_reset_email_address") === null &&
      localStorage.getItem("swapekis_email_address") === null
    ) {
      presentToast("error", "top-center", "Session has expired.", () => {
        window.location.reload();
      });
    }

    try {
      setLoading(true);
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "change_password",
          email:
            localStorage.getItem("swapekis_reset_email_address") ||
            localStorage.getItem("swapekis_email_address"),
          pin: password,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        presentToast("error", "top-center", data.message, () => {});
        // console.error("Error:", data.error);
      } else {
        localStorage?.removeItem("swapekis_reset_code");
        localStorage.removeItem("swapekis_reset_email_address");
        localStorage.removeItem("swapekis_email_address");
        presentToast("success", "top-center", data.message, () => {});
        window.location.href = "login";
        // navigate("/login", { replace: true });
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      <div className="signup-container">
        {/* Header Section */}
        <div className="signup-header d-flex justify-content-between align-items-center divides">
          <a href="/" className="back-button">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <span className="header-title">
            <img src="assets/images/logo.png" />
          </span>
          {!validUser ? (
            <a href="/login" className="login-link">
              LOGIN
            </a>
          ) : (
            <button onClick={logOut} className="login-link">
              LOG OUT
            </button>
          )}
        </div>
        {!canVerify && (
          <div className="text-center my-5">
            <h2 className="custom-title">Recover Password </h2>
            <p className="text-white">
              Please enter your email address, phone number or username and we
              will send you a verification code to reset your password.
            </p>
          </div>
        )}
        {canVerify && !correct && (
          <div className="text-center my-5">
            <h2 className="custom-title">VERIFY IT'S YOU </h2>
            <p>
              Enter the verification code we sent to{" "}
              <b>{localStorage.getItem("swapekis_reset_email_address")}</b>
            </p>
          </div>
        )}
        {correct && (
          <div className="text-center my-5">
            <h2 className="custom-title">Set A New Password </h2>
          </div>
        )}
        <div className="row">
          <div className="col-md-3">
            {" "}
            <div></div>
          </div>
          <div className="col-md-6">
            {!canVerify && (
              <form onSubmit={getResetCode} className="signup-card mx-5 mb-5">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter email or phone number"
                    value={identifier}
                    onInput={(e) => setIdentifier(e.target.value)}
                  />
                </div>
                {!validUser && (
                  <p className="terms">
                    Remembered your password?
                    <a href="/login" className="terms-link">
                      {" "}
                      LOGIN NOW
                    </a>
                  </p>
                )}
                <button type="submit" className="btn btn-primary btn-block">
                  SUBMIT
                </button>
              </form>
            )}
            {correct && (
              <form onSubmit={setNewPassword} className="signup-card mx-5 mb-5">
                <div className="form-group password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="New password"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  />
                  <i
                    className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                <div className="form-group password-field">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                    value={password2}
                    onInput={(e) => setPassword2(e.target.value)}
                  />
                  <i
                    className={`fa ${
                      showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                    }`}
                    onClick={toggleConfirmPasswordVisibility}
                  ></i>
                </div>

                <button type="submit" className="btn btn-primary btn-block">
                  CHANGE PASSWORD
                </button>
              </form>
            )}
            {canVerify && !correct && (
              <form onSubmit={compare} className="signup-card mx-5 mb-5">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter OTP"
                    value={otp}
                    onInput={(e) => setOtp(e.target.value)}
                  />
                </div>

                <p className="terms">
                  Didn't get the code?
                  <span
                    style={{ cursor: "pointer" }}
                    className="terms-link"
                    onClick={Resend}
                  >
                    {" "}
                    Resend code
                  </span>
                </p>
                <button type="submit" className="btn btn-primary btn-block">
                  VERIFY NOW
                </button>
              </form>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
