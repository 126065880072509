import React, { useState, useContext } from "react";
import { Context } from "../context/contex";
import SpinnerOverlay from "../components/Spinner";
import { showAlert } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();

  const { apiURL, validUser, loading, setLoading, logOut } = context;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const logIn = async (e) => {
    e.preventDefault();
    //  console.log("Error:", pass);
    if (!email || !password) {
      return showAlert({
        title: "WARNING!",
        text: "Please fill all required fields.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    try {
      setLoading(true);
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "user_login",
          identifier: email,
          pass: password,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        showAlert({
          title: "ERROR!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
        // console.error("Error:", data.error);
      } else {
        localStorage.setItem("swapekis_email_address", data.email);
        localStorage.setItem("swapekis_tx_pin", data.pin);
        window.location.replace("dashboard");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />

      <div className="signup-container">
        {/* Header Section */}
        <div className="signup-header d-flex justify-content-between align-items-center divides">
          <a href="/" className="back-button">
            <i className="fa fa-angle-double-left"></i>
          </a>
          <span className="header-title">
            <a href="https://swapeki.com/">
              <img src="assets/images/logo.png" />
            </a>
          </span>
          {!validUser ? (
            <a href="/register" className="login-link">
              SIGN UP
            </a>
          ) : (
            <button onClick={logOut} className="login-link">
              LOG OUT
            </button>
          )}
        </div>
        {!validUser && (
          <div className="text-center my-5">
            <h2 className="custom-title">LOGIN </h2>
          </div>
        )}
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            {!validUser ? (
              <form onSubmit={logIn} className="signup-card mx-5 mb-5">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-mail or Username"
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="form-group password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  />
                  <i
                    className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>

                <p className="terms">
                  Forgot password?
                  <a href="/recover-password" className="terms-link">
                    {" "}
                    Reset password
                  </a>
                </p>
                <button type="submit" className="btn btn-primary btn-block">
                  LOG IN
                </button>
              </form>
            ) : (
              <>
                <div style={{ fontSize: "100px" }} className="mt-5 text-center">
                  <i
                    className="fa fa-exclamation-triangle text-warning"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="">
                  You are currently logged in as{" "}
                  {localStorage.getItem("swapekis_email_address")}.{" "}
                  <a href="/dashboard" className="login-link">
                    CLICK HERE TO GO TO YOUR DASHBOARD
                  </a>{" "}
                  or{" "}
                  <button onClick={logOut} className="login-link">
                    CLICK HERE TO LOG OUT
                  </button>{" "}
                  if you wish to login to a different account.
                </div>
              </>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default Login;
