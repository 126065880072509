import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
  Alert,
} from "react-bootstrap";

const Settings = () => {
  // State management
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bvn, setBvn] = useState("");
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("airtime");
  const context = useContext(Context);

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
    getUserInfo,
    domain,
  } = context;

  const handleProfilePhotoSubmit = async (e) => {
    e.preventDefault();

    if (!profilePhoto) {
      presentToast(
        "error",
        "top-center",
        `Please select a photo to upload.`,
        () => {}
      );
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("profilePhoto", profilePhoto);
    formData.append("email", userInfo.email);

    formData.append("action", "change_photo");

    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        setProfilePhoto(null);
        await getUserInfo();
        presentToast(
          "success",
          "top-center",
          `Your profile photo has been updated.`,
          () => {}
        );
      } else {
        presentToast("error", "top-center", response.data.message, () => {});
      }
    } catch (error) {
      alert("An error occurred during submission.");
      console.error("Submission error", error);
    }
    setLoading(false);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    // Basic validation to ensure fields are filled
    if (!newPassword || !confirmPassword) {
      presentToast(
        "error",
        "top-center",
        "Please fill in all password fields.",
        () => {}
      );
      return;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      presentToast("error", "top-center", "Passwords do not match.", () => {});
      return;
    }

    setLoading(true); // Start loading spinner or disable form

    // Prepare data as URLSearchParams for application/x-www-form-urlencoded
    const formData = new URLSearchParams();
    formData.append("action", "update_password");
    formData.append("newPassword", newPassword);
    formData.append("email", userInfo.email);

    try {
      // Correctly send POST request with data and headers
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Handle successful response
      if (response.data.success) {
        setNewPassword("");
        setConfirmPassword("");
        presentToast(
          "success",
          "top-center",
          "Password has been changed successfully.",
          () => {}
        );

        // Optionally refresh user info
        await getUserInfo();
      } else {
        // Handle server-side validation errors or failures
        presentToast(
          "error",
          "top-center",
          response.data.message || "Failed to change password.",
          () => {}
        );
      }
    } catch (error) {
      // Handle network or unexpected errors
      presentToast(
        "error",
        "top-center",
        "An error occurred while changing password.",
        () => {}
      );
      console.error("Password change error:", error);
    } finally {
      setLoading(false); // Stop loading spinner or enable form
    }
  };

  const handleBankSettingsSubmit = async (e) => {
    e.preventDefault();

    // Basic validation to check all fields are filled
    if (!bankName || !accountNumber || !accountName || !bvn) {
      presentToast(
        "error",
        "top-center",
        "Please fill in all bank details.",
        () => {}
      );
      return;
    }

    if (isNaN(bvn) || bvn.length !== 10) {
      presentToast("error", "top-center", "Invalid BVN", () => {});
      return;
    }

    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("action", "update_bank_settings");
    formData.append("withdrawal_bank", bankName);
    formData.append("withdrawal_bank_account", accountNumber);
    formData.append("withdrawal_bank_name", accountName);
    formData.append("bvn", bvn);
    formData.append("email", userInfo.email);

    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Handle successful response
      if (response.data.success) {
        presentToast(
          "success",
          "top-center",
          "Withdrawal bank details has been changed successfully.",
          () => {}
        );

        await getUserInfo();
      } else {
        presentToast(
          "error",
          "top-center",
          response.data.message || "Failed to change bank details.",
          () => {}
        );
        console.error("Bank settings update error:", response.data);
      }
    } catch (error) {
      presentToast(
        "error",
        "top-center",
        "An error occurred while updating bank settings.",
        () => {}
      );
      console.error("Bank settings update error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePinChangeSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!oldPin || !newPin) {
      presentToast(
        "error",
        "top-center",
        "Please enter both old and new PINs.",
        () => {}
      );
      return;
    }
    if (newPin.length !== 4) {
      presentToast(
        "error",
        "top-center",
        "PIN must be exactly 4 digits.",
        () => {}
      );
      return;
    }

    if (+oldPin !== +userInfo.tx_pin) {
      presentToast("error", "top-center", "Old PIN is wrong!", () => {});
      return;
    }

    setLoading(true);

    const formData = new URLSearchParams();
    formData.append("action", "change_pin");
    formData.append("oldPin", oldPin);
    formData.append("newPin", newPin);
    formData.append("email", userInfo.email);

    try {
      // Correctly send POST request with data and headers
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.success) {
        setOldPin(""); // Clear the old PIN field
        setNewPin(""); // Clear the new PIN field
        presentToast(
          "success",
          "top-center",
          "Transaction PIN has been changed successfully.",
          () => {}
        );
      } else {
        console.error("FAILED:", response.data);
        presentToast(
          "error",
          "top-center",
          response.data.message || "Failed to change PIN.",
          () => {}
        );
      }
    } catch (error) {
      presentToast(
        "error",
        "top-center",
        "An error occurred while changing PIN.",
        () => {}
      );
      console.error("Change PIN error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setAccountName(userInfo?.withdrawal_bank_name);
    setAccountNumber(userInfo?.withdrawal_bank_account);
    setBankName(userInfo?.withdrawal_bank);
    setBvn(userInfo?.bvn);
  }, [userInfo]);

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <div className="text-center">
              <h2 className="mb-4">Settings</h2>
            </div>
            {/* Display a message if set */}
            {message && <Alert variant="info">{message}</Alert>}

            <Row className="text-center">
              <Col md={2}></Col>
              <Col md={8}>
                <Row className="mb-4">
                  <Col md={12}>
                    <div className="text-center">
                      <h3>Change Profile Photo</h3>
                      <img
                        src={
                          userInfo?.photo !== null
                            ? domain + userInfo?.photo
                            : "assets/images/avatar.svg"
                        }
                        height={100}
                        className="rounded-circle"
                      />
                    </div>

                    <Form onSubmit={handleProfilePhotoSubmit}>
                      <Form.Group controlId="formProfilePhoto" className="mb-3">
                        <Form.Label>Upload Photo</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => setProfilePhoto(e.target.files[0])}
                        />
                      </Form.Group>
                      <div className="text-center">
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>

                {/* Change Password */}
                <Row className="mb-4">
                  <Col md={12}>
                    <h3>Change Password</h3>

                    <Form onSubmit={handlePasswordSubmit}>
                      <Form.Group
                        controlId="formNewPassword"
                        className="mb-3 form-group"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="formConfirmPassword"
                        className="mb-3 form-group"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm new password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group>
                      <div className="text-center">
                        <Button variant="primary" type="submit">
                          Change Password
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>

                {/* Bank Settings */}
                <Row className="mb-4">
                  <Col md={12}>
                    <h3>Bank Settings</h3>
                    <Form onSubmit={handleBankSettingsSubmit}>
                      <Form.Group controlId="formBankName" className="mb-3">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter bank name"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="formAccountNumber"
                        className="mb-3"
                      >
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter account number"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formAccountName" className="mb-3">
                        <Form.Label>Account Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter account name"
                          value={accountName}
                          onChange={(e) => setAccountName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBVN" className="mb-3">
                        <Form.Label>BVN</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter BVN"
                          value={bvn}
                          onChange={(e) => setBvn(e.target.value)}
                        />
                      </Form.Group>
                      <div className="text-center">
                        <Button variant="primary" type="submit">
                          Save Changes
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>

                {/* Change Transaction PIN */}
                <Row className="mb-4">
                  <Col md={12}>
                    <h3>Change Transaction PIN</h3>

                    <Form onSubmit={handlePinChangeSubmit}>
                      <Form.Group controlId="formOldPin" className="mb-3">
                        <Form.Label>Old PIN</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter old PIN"
                          value={oldPin}
                          onChange={(e) => setOldPin(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group controlId="formNewPin" className="mb-3">
                        <Form.Label>New PIN</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new PIN"
                          value={newPin}
                          onChange={(e) => setNewPin(e.target.value)}
                        />
                      </Form.Group>
                      <div className="text-center">
                        <Button variant="primary" type="submit">
                          Change PIN
                        </Button>
                      </div>
                    </Form>
                  </Col>
                  <Col xs="12" className="text-center my-5">
                    Need some help?
                    <Button
                      variant="link"
                      className="text-warning"
                      as={Link}
                      to=""
                    >
                      <b>Contact support</b>
                    </Button>
                  </Col>
                  <p>&nbsp;</p>
                </Row>
              </Col>
              <Col md={2}></Col>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Settings;
