import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  getPercentage,
  getFirstWord,
  generateRequestId,
  presentToast,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SpinnerOverlay from "../../components/Spinner";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  ListGroup,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";

const TopUp = () => {
  const [type, setType] = useState("airtime");
  const [priceData, setPriceData] = useState(0);
  const context = useContext(Context);
  const amounts = [100, 200, 500, 1000, 5000, 10000];

  const [airtimeNetworkId, setAirtimeNetworkId] = useState("");
  const [airtimeNetworkName, setAirtimeNetworkName] = useState("");
  const [airtimeMinAmount, setAirtimeMinAmount] = useState("");
  const [airtimeMaxAmount, setAirtimeMaxAmount] = useState("");
  const [dataNetworkId, setDataNetworkId] = useState("");
  const [dataNetworkName, setDataNetworkName] = useState("");
  const [dataVariations, setDataVariations] = useState([]);
  const [dataVariationCode, setDataVariationCode] = useState("");

  const [airtimeAmount, setAirtimeAmount] = useState("");
  const [intAmount, setIntAmount] = useState("");

  const [airtimePhoneNumber, setAirtimePhoneNumber] = useState("");
  const [dataPhoneNumber, setDataPhoneNumber] = useState("");
  const [intPhoneNumber, setIntPhoneNumber] = useState("");

  const [smileEmail, setSmileEmail] = useState("");

  const [pin, setPin] = useState("");

  const [busy, setBusy] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [intCountries, setIntCountries] = useState([]);
  const [intCountry, setIntCountry] = useState({});
  const [intProducts, setIntProducts] = useState([]);
  const [intProduct, setIntProduct] = useState({});
  const [intOperators, setIntOperators] = useState([]);

  const [intOperator, setIntOperator] = useState({});
  const [intVariations, setIntVariations] = useState([]);
  const [intVariation, setIntVariation] = useState("");
  const [intFixedName, setIntFixedName] = useState("");
  const [intFixedAmount, setIntFixedAmount] = useState(0);

  const [airtimeService, setAirtimeService] = useState([
    {
      serviceID: "airtel",
      name: "Airtel Airtime VTU",
      minimium_amount: "50",
      maximum_amount: "8000",
      convinience_fee: "0 %",
      product_type: "flexible",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/Airtel-Airtime.jpg",
    },
    {
      serviceID: "mtn",
      name: "MTN Airtime VTU",
      minimium_amount: "5",
      maximum_amount: "6000",
      convinience_fee: "0 %",
      product_type: "flexible",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/MTN-Airtime.jpg",
    },
    {
      serviceID: "glo",
      name: "GLO Airtime VTU",
      minimium_amount: "10",
      maximum_amount: "8000",
      convinience_fee: "0 %",
      product_type: "flexible",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/GLO-Airtime.jpg",
    },
    {
      serviceID: "etisalat",
      name: "9mobile Airtime VTU",
      minimium_amount: "5",
      maximum_amount: "8000",
      convinience_fee: "0 %",
      product_type: "flexible",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/9mobile-Airtime.jpg",
    },
  ]);
  const [dataService, setDataService] = useState([
    {
      serviceID: "airtel-data",
      name: "Airtel Data",
      minimium_amount: "",
      maximum_amount: "",
      convinience_fee: "0 %",
      product_type: "fix",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/Airtel-Data.jpg",
    },
    {
      serviceID: "mtn-data",
      name: "MTN Data",
      minimium_amount: "",
      maximum_amount: "",
      convinience_fee: "0 %",
      product_type: "fix",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/MTN-Data.jpg",
    },
    {
      serviceID: "glo-data",
      name: "GLO Data",
      minimium_amount: "",
      maximum_amount: "",
      convinience_fee: "0 %",
      product_type: "fix",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/GLO-Data.jpg",
    },
    {
      serviceID: "etisalat-data",
      name: "9mobile Data",
      minimium_amount: "",
      maximum_amount: "",
      convinience_fee: "0 %",
      product_type: "fix",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/9mobile-Data.jpg",
    },
    {
      serviceID: "smile-direct",
      name: "Smile Payment",
      minimium_amount: "100",
      maximum_amount: "150000",
      convinience_fee: "N0.00",
      product_type: "fix",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/Smile-Payment.jpg",
    },
    {
      serviceID: "spectranet",
      name: "Spectranet",
      minimium_amount: "",
      maximum_amount: "",
      convinience_fee: "N0.00",
      product_type: "fix",
      image: "https://sandbox.vtpass.com/resources/products/200X200/",
    },
    {
      serviceID: "9mobile-sme-data",
      name: "9mobile Sme Data",
      minimium_amount: "",
      maximum_amount: "",
      convinience_fee: "0 %",
      product_type: "fix",
      image:
        "https://sandbox.vtpass.com/resources/products/200X200/9mobile-Sme-Data.jpg",
    },
  ]);

  const {
    apiURL,
    ufitpayKey,
    ufitpayBase,
    ufitpayToken,
    userInfo,
    vtpassApiKey,
    vtpassBase,
    vtpassPubKey,
    vtpassSecKey,
    vtpBalance,
    settings,
    validUser,
    setLoading,
    loading,
  } = context;

  const handleTab = (type) => {
    setType(type);
    setAirtimeNetworkId("");
    setAirtimeNetworkName("");
    setDataNetworkId("");
    setDataNetworkName("");
    setDataVariations([]);
    setDataVariationCode("");
    setPriceData(0);
    // setIntCountries([]);
    setIntOperators([]);
    setIntProducts([]);
    setIntVariation("");
    setIntVariations([]);
    setIntCountry({});
    setIntOperator({});
    setIntProduct({});
    setIntFixedAmount(0);

    setIntFixedName("");
    //setIntPhoneNumber("");
  };

  const handleClickAirtime = (id, name, min, max) => {
    setAirtimeNetworkId(id);
    setAirtimeNetworkName(name);

    setAirtimeMinAmount(min);
    setAirtimeMaxAmount(max);
  };

  const handleClickData = (id, name) => {
    setDataNetworkId(id);
    setDataNetworkName(name);
  };

  const fetchServiceID = async (identifier) => {
    try {
      const response = await fetch(
        vtpassBase + `services?identifier=${identifier}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        //   console.log("S", result.content);
        return result.content;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching service ID:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const as = await fetchServiceID("airtime");
        const ds = await fetchServiceID("data");

        if (as) {
          const filteredServices = as.filter(
            (service) => service.serviceID !== "foreign-airtime"
          );

          setAirtimeService(filteredServices);
        }

        if (ds) {
          setDataService(ds);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
    fetchServices();
  }, [vtpassBase, vtpassApiKey, vtpassPubKey]);

  const getDataVariations = async (identifier) => {
    setLoading(true);
    setDataVariations([]);
    try {
      const response = await fetch(
        vtpassBase + `service-variations?serviceID=${identifier}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("VAR", result.content.varations);
        setDataVariations(result.content.varations);
        setLoading(false);
      } else {
        console.error("Error fetching service variations:", result);
        setLoading(false);

        return null;
      }
    } catch (error) {
      console.error("Error getting variations:", error);
      setLoading(false);

      return null;
    }
  };

  useEffect(() => {
    if (!dataNetworkId) {
      return;
    }
    getDataVariations(dataNetworkId);
  }, [dataNetworkId]);

  const verifySmileEmail = async (email, id) => {
    if (!email || !id) {
      presentToast(
        "error", // Type: success, error, info, warning, etc.
        "top-center",
        "Invalid Smile email or serviceID.",
        () => {}
      );

      return;
    }

    const data = {
      billersCode: email,
      serviceID: id,
    };

    try {
      const response = await fetch(vtpassBase + "merchant-verify/smile/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (
        result.code === "000" &&
        result.content.AccountList.Account.AccountId !== null
      ) {
        console.log(
          "SMILE ID:",
          result.content.AccountList.Account[0].AccountId
        );
        return result.content.AccountList.Account[0].AccountId;
      } else {
        console.log("Not found:", result);
        presentToast(
          "error", // Type: success, error, info, warning, etc.
          "top-center",
          "This smile email was not found.",
          () => {}
        );
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.error("Error getting smile ID:", error);
      presentToast(
        "error", // Type: success, error, info, warning, etc.
        "top-center",
        "Error getting smile ID",
        () => {}
      );
      setLoading(false);
    }
  };

  const airtimeTopUp = async (pin) => {
    if (userInfo.tx_pin === null) {
      presentToast(
        "warning", // Type: success, error, info, warning, etc.
        "top-center",
        "Please set a transaction PIN",
        () => {
          window.location.replace("set-pin");
        }
      );
      return;
    }

    // Validation checks...
    if (!airtimeAmount) {
      presentToast(
        "warning",
        "top-center",
        "Please enter airtime amount.",
        () => {}
      );

      return;
    }
    if (!airtimePhoneNumber) {
      presentToast(
        "warning",
        "top-center",
        `Please enter a valid ${airtimeNetworkName} phone number.`,
        () => {}
      );

      return;
    }
    if (
      +airtimeAmount > +airtimeMaxAmount ||
      +airtimeAmount < +airtimeMinAmount
    ) {
      presentToast(
        "warning",
        "top-center",
        `Amount must be between ₦${airtimeMinAmount} and ₦${airtimeMaxAmount}`,
        () => {}
      );

      return;
    }
    if (+airtimeAmount > +vtpBalance) {
      presentToast(
        "warning",
        "top-center",
        `Service not available at the moment.`,
        () => {}
      );

      return;
    }
    if (+airtimeAmount > +userInfo?.ngn_balance) {
      presentToast(
        "warning",
        "top-center",
        `You do not have sufficient funds in your wallet.`,
        () => {
          window.location.replace("deposit");
        }
      );

      return;
    }

    const service = airtimeService.find(
      (s) => s.serviceID === airtimeNetworkId
    );
    if (!service) {
      presentToast(
        "warning",
        "top-center",
        `Airtime top-up for ${airtimeNetworkName} is currently not available.`,
        () => {}
      );

      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("warning", "top-center", `Incorrect PIN!`, () => {});

      return;
    }

    const id = generateRequestId();
    setLoading(true);
    const data = {
      serviceID: airtimeNetworkId,
      amount: +airtimeAmount,
      request_id: id,
      phone: airtimePhoneNumber,
    };

    try {
      const response = await fetch(vtpassBase + "pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.code === "000" || result.code === "099") {
        console.log("Airtime top-up successful:", result);
        requeryTransaction(id);
      } else {
        presentToast(
          "error",
          "top-center",
          "Airtime top-up failed and you were not debited.",
          () => {}
        );

        console.log("Airtime top-up failed:", result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error performing airtime top-up:", error);
      setLoading(false);
    }
  };

  const dataTopUp = async (pin) => {
    if (userInfo.tx_pin === null) {
      presentToast(
        "warning",
        "top-center",
        `Please set your transaction PIN first.`,
        () => {
          window.location.replace("set-pin");
        }
      );

      return;
    }

    // Validation checks...

    if (!dataPhoneNumber) {
      presentToast(
        "warning",
        "top-center",
        `Please enter a valid ${dataNetworkName} phone number.`,
        () => {}
      );

      return;
    }

    if (+priceData > +vtpBalance) {
      presentToast(
        "warning",
        "top-center",
        `Service not available at the moment.`,
        () => {}
      );

      return;
    }
    if (+priceData > +userInfo?.ngn_balance) {
      presentToast(
        "warning",
        "top-center",
        `You do not have sufficient funds in your wallet.`,
        () => {
          window.location.replace("deposit");
        }
      );

      return;
    }

    const service = dataService.find((s) => s.serviceID === dataNetworkId);
    if (!service) {
      presentToast(
        "warning",
        "top-center",
        `Data top-up for ${dataNetworkName} is currently not available.`,
        () => {}
      );

      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("warning", "top-center", `Incorrect PIN!`, () => {});

      return;
    }

    const id = generateRequestId();
    console.log("NET:", dataVariationCode);

    if (
      // "a" == "a"
      dataNetworkName == "Airtel" ||
      dataNetworkName == "MTN" ||
      dataNetworkName == "GLO" ||
      dataNetworkName == "9mobile" ||
      dataNetworkName == "Spectranet"
    ) {
      setLoading(true);

      const data = {
        serviceID: dataNetworkId,
        // amount: +airtimeAmount,
        request_id: id,
        billersCode: String(dataPhoneNumber),
        variation_code: dataVariationCode,
        phone: String(dataPhoneNumber),
      };
      console.log("DATA:", data);

      try {
        const response = await fetch(vtpassBase + "pay", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "secret-key": vtpassSecKey,
          },
          body: JSON.stringify(data),
        });

        const result = await response.json();
        if (result.code === "000" || result.code === "099") {
          console.log("Data top-up successful:", result);
          requeryTransaction(id);
        } else {
          presentToast(
            "error",
            "top-center",
            "Data top-up failed and you were not debited.",
            () => {}
          );

          console.log("Data top-up failed:", result);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error performing data top-up:", error);
        setLoading(false);
      }
    } else if (dataNetworkName == "Smile") {
      setLoading(true);

      const smileId = await verifySmileEmail(smileEmail, dataNetworkId);

      if (smileId == null) {
        return;
      }
      //console.log("LOG", smileId);
      const data = {
        serviceID: dataNetworkId,
        // amount: +airtimeAmount,
        request_id: id,
        billersCode: String(smileId),
        variation_code: dataVariationCode,
        phone: String(dataPhoneNumber),
      };

      try {
        const response = await fetch(vtpassBase + "pay", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            "api-key": vtpassApiKey,
            "secret-key": vtpassSecKey,
          },
          body: JSON.stringify(data),
        });

        const result = await response.json();
        if (result.code === "000" || result.code === "099") {
          console.log("Data top-up successful:", result);
          requeryTransaction(id);
        } else {
          presentToast(
            "error",
            "top-center",
            "Data top-up failed and you were not debited.",
            () => {}
          );
          console.log("Data top-up failed:", result);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error performing data top-up:", error);
        setLoading(false);
      }
    }
  };

  const requeryTransaction = async (requestId) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${vtpassBase}requery`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "secret-key": vtpassSecKey,
          },
          body: JSON.stringify({ request_id: requestId }),
        });

        const result = await response.json();
        if (
          result.code === "000" &&
          result.content.transactions.status === "delivered"
        ) {
          console.log("Transaction delivered:", result);
          clearInterval(interval);
          const cost =
            type == "international"
              ? (
                  +result.content.transactions.amount +
                  +result.content.transactions.convinience_fee
                ).toString()
              : result.content.transactions.amount;
          const response = await fetch(apiURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              action: "record_tx_and_debit",
              title: `${
                type == "airtime"
                  ? airtimeNetworkName.toUpperCase()
                  : type == "data"
                  ? dataNetworkName.toUpperCase()
                  : "International".toUpperCase()
              } ${
                type == "airtime"
                  ? "airtime"
                  : type == "data"
                  ? "data"
                  : "international"
              } purchase.`,
              desc: `${
                type == "airtime"
                  ? airtimeNetworkName.toUpperCase()
                  : type == "data"
                  ? dataNetworkName.toUpperCase()
                  : "International".toUpperCase()
              } ${
                type == "airtime"
                  ? "airtime"
                  : type == "data"
                  ? "data"
                  : "international"
              } purchase.`,
              amount: cost,
              ref: result.content.transactions.transactionId,
              our_ref: requestId,
              status: "Complete",
              email: String(userInfo?.email),
              payload: JSON.stringify(result),
              type: type,
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          await context.getUserInfo();
          await context.getTransactions();
          setLoading(false);
          presentToast(
            "success",
            "top-center",
            `Your ${
              type == "airtime"
                ? "airtime"
                : type == "data"
                ? "data"
                : "international"
            } purchase was successful.`,
            () => {}
          );
        } else {
          presentToast(
            "success",
            "top-center",
            `${result.content.transactions.status}`,
            () => {}
          );

          console.log(
            "Transaction status:",
            result.content.transactions.status
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error re-querying transaction:", error);
        clearInterval(interval);
        setLoading(false);
      }
    }, 2000); // Re-query every 2 seconds
  };

  useEffect(() => {
    console.log("SHOW:", intCountry);
  }, [intCountry]);

  //==========INTERNATIONAL AIRTIME AND DATA======

  const getIntCountries = async () => {
    setIntCountries([]);
    setIntProducts([]);
    setIntVariations([]);
    setIntOperators([]);
    setLoading(true);
    try {
      const response = await fetch(
        vtpassBase + `get-international-airtime-countries`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        // console.log("S", result.content.countries);
        setIntCountries(result.content.countries);
        setLoading(false);
      } else {
        console.error("No countries:", result);

        setIntCountries([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
      setLoading(false);

      return null;
    }
  };

  const getIntProducts = async (code) => {
    // setIntCountries([]);
    setIntProducts([]);
    setIntVariations([]);
    setIntOperators([]);
    setLoading(true);
    try {
      const response = await fetch(
        vtpassBase + `get-international-airtime-product-types?code=${code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("S", result.content);
        setIntProducts(result.content);
        setLoading(false);
      } else {
        console.error("No products:", result);

        setIntProducts([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);

      return null;
    }
  };

  const getIntOperators = async (code, id) => {
    // setIntCountries([]);
    // setIntProducts([]);
    setIntVariations([]);
    setIntOperators([]);
    setLoading(true);
    try {
      const response = await fetch(
        vtpassBase +
          `get-international-airtime-operators?code=${code}&product_type_id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("OP", result.content);
        setIntOperators(result.content);
        setLoading(false);
      } else {
        console.error("NO OPERATORS:", result);

        setIntOperators([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching operators:", error);
      setLoading(false);

      return null;
    }
  };

  const getIntVariations = async (opid, prodid) => {
    setLoading(true);
    try {
      const response = await fetch(
        vtpassBase +
          `service-variations?serviceID=foreign-airtime&operator_id=${opid}&product_type_id=${prodid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-key": vtpassApiKey,
            "public-key": vtpassPubKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.response_description === "000" && result.content) {
        console.log("V", result.content.varations);
        setIntVariations(result.content.varations);
        setLoading(false);
      } else {
        console.error("NO variations:", result);

        setIntVariations([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching variations:", error);
      setLoading(false);

      return null;
    }
  };

  useEffect(() => {
    getIntCountries();
  }, []);

  useEffect(() => {
    if (!intCountry.code) {
      setIntProducts([]);
      setIntProduct({});
      setIntOperators([]);
      setIntOperator({});
      setIntVariations([]);
      setIntVariation("");
      return;
    }
    getIntProducts(intCountry.code);
  }, [intCountry.code]);

  useEffect(() => {
    if (
      Object.keys(intCountry).length === 0 ||
      Object.keys(intProducts).length === 0
    ) {
      setIntOperators([]);
      setIntOperator({});
      return;
    }
    getIntOperators(intCountry.code, intProduct.product_type_id);
  }, [intCountry.code, intProduct.product_type_id]);

  useEffect(() => {
    if (
      Object.keys(intOperator).length === 0 ||
      Object.keys(intProduct).length === 0
    ) {
      setIntVariations([]);
      setIntVariation("");
      return;
    }
    getIntVariations(intOperator.operator_id, intProduct.product_type_id);
  }, [intOperator]);

  const intTopUp = async (pin) => {
    const cost =
      intVariations[0].fixedPrice == "Yes"
        ? intFixedAmount
        : +intAmount * +intVariations[0].variation_rate;

    if (userInfo.tx_pin === null) {
      presentToast(
        "warning",
        "top-center",
        `Please set your transaction PIN first.`,
        () => {
          window.location.replace("set-pin");
        }
      );

      return;
    }

    // Validation checks...

    if (!intPhoneNumber) {
      presentToast(
        "warning",
        "top-center",
        `Please enter a valid ${type} phone number.`,
        () => {}
      );

      return;
    }

    if (
      intVariations[0].fixedPrice === "No" &&
      (+intAmount > +intVariations[0].variation_amount_max ||
        +intAmount < +intVariations[0].variation_amount_min)
    ) {
      presentToast(
        "warning",
        "top-center",
        `Amount must be between ${intVariations[0].variation_amount_min} and ${intVariations[0].variation_amount_max}`,
        () => {}
      );

      return;
    }

    if (+cost > +vtpBalance) {
      presentToast(
        "warning",
        "top-center",
        `Service not available at the moment.`,
        () => {}
      );

      return;
    }

    if (+cost > +userInfo?.ngn_balance) {
      presentToast(
        "warning",
        "top-center",
        `You do not have sufficient funds in your wallet.`,
        () => {
          window.location.replace("deposit");
        }
      );

      return;
    }

    if (+pin !== +userInfo.tx_pin) {
      presentToast("warning", "top-center", `Incorrect PIN!`, () => {});

      return;
    }

    const id = generateRequestId();
    console.log("vcode:", intVariation);

    setLoading(true);

    const data = {
      serviceID: "foreign-airtime",
      request_id: id,
      billersCode: String(intPhoneNumber),
      phone: String(intPhoneNumber),
      operator_id: intOperator.operator_id,
      country_code: intCountry.code,
      product_type_id: intProduct.product_type_id,
      email: userInfo.email,
    };

    // Conditionally add the amount property
    if (intVariations[0].fixedPrice === "No") {
      data.amount = +intAmount;
      data.variation_code = intVariations[0].variation_code;
    } else {
      data.amount = +intFixedAmount;
      data.variation_code = intVariation;
    }

    console.log("DATA:", data);

    try {
      const response = await fetch(vtpassBase + "pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": vtpassApiKey,
          "secret-key": vtpassSecKey,
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.code === "000" || result.code === "099") {
        console.log("International top-up successful:", result);
        requeryTransaction(id);
      } else {
        presentToast(
          "error",
          "top-center",
          "International top-up failed and you were not debited.",
          () => {}
        );

        console.log("International top-up failed:", result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error performing international top-up:", error);
      setLoading(false);
    }
  };

  const handleValueChange = (value) => {
    const [newName, newPriceStr, vCode] = value.split(",");
    const newPrice = parseInt(newPriceStr);

    if (!isNaN(newPrice)) {
      //setNameData(newName);
      setPriceData(newPrice);
      setDataVariationCode(vCode);
      //setDataNetworkName(newName);
    }
  };

  return (
    <>
      <SpinnerOverlay loading={loading} />
      <ToastContainer />
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container ">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row>
              <Col xs="1"></Col>
              <Col xs="10">
                <Row>
                  <Col className="text-center" size="12">
                    <h2 className="mb-5">
                      Top Up{" "}
                      {type == "airtime"
                        ? airtimeNetworkName.toUpperCase()
                        : dataNetworkName.toUpperCase()}{" "}
                      {type.toUpperCase()}
                    </h2>
                  </Col>
                  <Col xs="12" className="text-center">
                    <Tabs
                      defaultActiveKey="airtime"
                      id="justify-tab-example"
                      className="mb-3"
                      justify
                      onSelect={(selectedKey) => handleTab(selectedKey)} // Use onSelect here
                    >
                      <Tab eventKey="airtime" title="AIRTIME">
                        <Row>
                          {airtimeService.length > 0 && (
                            <Col xs="12" className="text-center">
                              <div className="image-grid-top-up my-3">
                                {airtimeService.map((service, id) => (
                                  <div
                                    key={id}
                                    className={`image-item-top-up ${
                                      airtimeNetworkId === service.serviceID
                                        ? "selected-top-up"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleClickAirtime(
                                        service.serviceID,
                                        getFirstWord(service.name),
                                        service.minimium_amount,
                                        service.maximum_amount
                                      )
                                    }
                                    style={{ marginBottom: "4px" }}
                                  >
                                    <img
                                      src={service.image}
                                      alt={service.name}
                                      className="grid-image-top-up"
                                      style={{
                                        transform:
                                          airtimeNetworkId === service.serviceID
                                            ? "scale(1.5)"
                                            : "scale(1)",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                        </Row>

                        <Row className="text-center mt-4">
                          <Col xs={12} className="mb-3">
                            <InputGroup>
                              <InputGroup.Text
                                id="self-addon1"
                                onClick={() =>
                                  setAirtimePhoneNumber(userInfo?.phone)
                                }
                              >
                                Self
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                placeholder={`${airtimeNetworkName.toUpperCase()} Phone Number`}
                                value={airtimePhoneNumber}
                                onChange={(e) =>
                                  setAirtimePhoneNumber(e.target.value)
                                }
                              />
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="text-center">
                          <Col xs={12}>
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                {naira}
                              </InputGroup.Text>
                              <Form.Control
                                type="tel"
                                placeholder="Amount"
                                value={airtimeAmount}
                                onChange={(e) =>
                                  setAirtimeAmount(e.target.value)
                                }
                              />
                            </InputGroup>
                            <Row className="mt-3">
                              {amounts.map((amount, index) => (
                                <Col
                                  xs={4}
                                  key={index}
                                  className="text-center mb-3"
                                >
                                  <Button
                                    className="amount-btn"
                                    variant="secondary"
                                    onClick={() => setAirtimeAmount(amount)}
                                  >
                                    {naira}
                                    {amount.toLocaleString()}
                                  </Button>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>

                        {/* Pay Button */}
                        {airtimeNetworkId &&
                          airtimeAmount &&
                          airtimePhoneNumber && (
                            <Row>
                              <Col xs={12}>
                                <Button onClick={() => setPinModal(true)}>
                                  Pay{" "}
                                  {airtimeAmount &&
                                    naira + formatNumber(airtimeAmount)}{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </Button>
                              </Col>
                            </Row>
                          )}
                      </Tab>
                      <Tab eventKey="data" title="DATA">
                        <Container className="my-5">
                          {/* Data Service Grid */}
                          <Row>
                            <Col xs={12} className="text-center">
                              <div className="image-grid-top-up mb-3">
                                {dataService.map((service, id) => (
                                  <div
                                    key={id}
                                    className={`image-item-top-up ${
                                      dataNetworkId === service.serviceID
                                        ? "selected-top-up"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleClickData(
                                        service.serviceID,
                                        getFirstWord(service.name)
                                      )
                                    }
                                    style={{ marginBottom: "4px" }}
                                  >
                                    <img
                                      src={service.image}
                                      alt={service.name}
                                      className="grid-image-top-up"
                                      style={{
                                        transform:
                                          dataNetworkId === service.serviceID
                                            ? "scale(1.5)"
                                            : "scale(1)",
                                      }}
                                    />
                                    <p>{service.name}</p>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          </Row>

                          {/* Smile Email Input */}
                          <Row>
                            {dataNetworkName === "Smile" && (
                              <Col xs={12}>
                                <InputGroup>
                                  <InputGroup.Text
                                    id="smile-addon1"
                                    onClick={() =>
                                      setSmileEmail(userInfo?.email)
                                    }
                                  >
                                    Self
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="email"
                                    placeholder={`${dataNetworkName.toUpperCase()} Email`}
                                    value={smileEmail}
                                    onChange={(e) =>
                                      setSmileEmail(e.target.value)
                                    }
                                  />
                                </InputGroup>
                              </Col>
                            )}

                            {/* Phone Number Input */}
                            <Col xs={12}>
                              <InputGroup>
                                <InputGroup.Text
                                  id="smile-addon1"
                                  onClick={() =>
                                    setDataPhoneNumber(userInfo?.phone)
                                  }
                                >
                                  Self
                                </InputGroup.Text>
                                <Form.Control
                                  type="tel"
                                  placeholder={`${dataNetworkName.toUpperCase()} Phone Number`}
                                  value={dataPhoneNumber}
                                  onChange={(e) =>
                                    setDataPhoneNumber(e.target.value)
                                  }
                                />
                              </InputGroup>
                            </Col>
                          </Row>

                          {/* Data Plan Selection */}
                          <Row>
                            {dataVariations.length > 0 && (
                              <Col xs={12}>
                                <Form.Group>
                                  <Form.Label>{`Choose ${dataNetworkName.toUpperCase()} ${type.toUpperCase()} plan`}</Form.Label>
                                  <Form.Select
                                    onChange={(e) =>
                                      handleValueChange(e.target.value)
                                    }
                                  >
                                    <option disabled selected>
                                      Chose a plan
                                    </option>
                                    {dataVariations.map((plan, index) => (
                                      <option
                                        key={index}
                                        value={`${plan.name},${plan.variation_amount},${plan.variation_code}`}
                                      >
                                        {plan.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            )}
                          </Row>

                          {/* Pay Button and Help Link */}
                          {dataNetworkId && dataPhoneNumber && (
                            <Row className="my-4">
                              <Col xs={12}>
                                <Button onClick={() => setPinModal(true)}>
                                  Pay ₦{formatNumber(priceData.toString())}{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </Container>
                      </Tab>
                    </Tabs>
                  </Col>

                  <Col xs="12" className="text-center my-5">
                    Need some help?
                    <Button
                      variant="link"
                      className="text-warning"
                      as={Link}
                      to="https://swapeki.com/contact-us/"
                    >
                      <b>Contact support</b>
                    </Button>
                  </Col>
                  <p>&nbsp;</p>
                </Row>
              </Col>
              <Col xs="1"></Col>
            </Row>
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}

      {/* Modal for PIN input */}
      <Modal
        show={pinModal}
        onHide={() => setPinModal(false)}
        backdrop="static"
        dialogClassName="modal-bg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Authorize Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter your PIN to authorize this transaction.</p>
          <Form.Group>
            <Form.Control
              style={{ color: "#333333 !important" }}
              type="password"
              placeholder="Enter PIN"
              maxLength="4"
              inputMode="numeric"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPinModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (type === "airtime") {
                airtimeTopUp(pin);
              } else if (type === "international") {
                intTopUp(pin);
              } else {
                dataTopUp(pin);
              }
              setPinModal(false);
            }}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TopUp;
